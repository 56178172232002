import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { TmCollectionLoader } from '@tm-shared/dataloader';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TmPolicyService } from '../policy/policy.service';
import { Router } from '@angular/router';

@Injectable()
export class TmPolicyApiService extends TmCollectionLoader<ApiPolicy.Policy> {
  public readonly src = '/api/policy';
  public idAttribute = 'POLICY_ID';

  constructor(
    _http: HttpClient,
    private _notify: IwNotificationsService,
    private _policyService: TmPolicyService,
    private _t: TranslateService,
    private router: Router
  ) {
    super(_http);
  }

  public getEmptyPolicyObject(policyType: 'AGENT'): ApiPolicy.PolicyAgent;
  public getEmptyPolicyObject(policyType: 'PERSON'): ApiPolicy.PolicyPerson;
  public getEmptyPolicyObject(policyType: 'OBJECT'): ApiPolicy.PolicyObject;
  public getEmptyPolicyObject(policyType: any): ApiPolicy.Policy {
    return this._policyService.getEmptyPolicy(policyType);
  }

  public publish() {
    return this._http.get(`${this.src}/publish`);
  }

  public setItemsToPolicy(
    policy: ApiPolicy.Policy,
    items: (ApiPolicy.PolicyObjectProtectedItem | ApiPolicy.PolicyPersonProtectedItem)[]
  ) {
    policy.DATA!.ITEMS = items;
  }

  public createPolicyAndNavigate(policy$: Observable<ApiPolicy.Policy>) {
    policy$
      .pipe(
        switchMap((data) =>
          this.create(data).pipe(
            catchError((error: HttpErrorResponse) => {
              this._notify.error(
                this._t.instant('@tm-shared.error.message'),
                this._t.instant('@tm-shared.error.policy.default-error')
              );
              return throwError(error);
            })
          )
        )
      )
      .subscribe((response) => {
        this.router.navigate(['policy', response.data.POLICY_ID]);
      });
  }
}
