import FilterObject = TmGrid.grid.FilterObject;
import SortObject = TmGrid.grid.SortObject;
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

const SORT_REGEXP = 'sort\\[([\\w\\.]+)\\]=(asc|desc)';
const FILTER_REGEXP = 'filter\\[([\\w\\.]+)\\]([[]])?=([^&]+)';

type FilterSortNullable = FilterObject | SortObject | null;

@Injectable()
export class TmGridState implements OnDestroy {
  /**
   * Get encoded storeId for url
   */
  private get _safeStoreId(): string {
    return this.storeId ? encodeURIComponent(this.storeId) : '';
  }

  /**
   * Regexp for params extraction from URL
   */
  private get _getPageRegexp(): RegExp {
    return new RegExp(`[?&]${TmGridState.storeIdPrefix}${this._safeStoreId}=(\\d+),(\\d+)`);
  }
  private get _getFilterRegexp(): RegExp {
    return new RegExp(`[?&]${this._safeStoreId}${FILTER_REGEXP}`, 'g');
  }
  private get _getSortRegexp(): RegExp {
    return new RegExp(`[?&]${this._safeStoreId}${SORT_REGEXP}`, 'g');
  }

  public static storeIdPrefix = 'grid_';

  public storeKey: TmGrid.grid.StoreKey;
  public storeId: string;

  public page$: Observable<number | null>;
  public pageLimit$: Observable<number | null>;
  public pageLimitStable$: Observable<number>;
  public start$: Observable<number | null>;
  public filter$: Observable<TmGrid.grid.FilterObject | null>;
  public filterMapped$: Observable<TmGrid.grid.FilterObject | null>;
  public sort$: Observable<TmGrid.grid.SortObject | null>;
  public sortMapped$: Observable<TmGrid.grid.SortObject | null>;

  private _page$ = new BehaviorSubject<number | null>(null);
  private _pageLimit$ = new BehaviorSubject<number | null>(null);
  private _filter$ = new BehaviorSubject<TmGrid.grid.FilterObject | null>(null);
  private _sort$ = new BehaviorSubject<TmGrid.grid.SortObject | null>(null);

  constructor(private _router: Router, private _activatedRoute: ActivatedRoute) {
    this.page$ = this._page$.pipe(distinctUntilChanged());
    this.pageLimit$ = this._pageLimit$.pipe(distinctUntilChanged());
    this.pageLimitStable$ = this.pageLimit$.pipe(filter((lim): lim is number => !!lim));
    this.filter$ = this._filter$.asObservable();
    this.filterMapped$ = this._filter$.pipe(map((item) => this._mapToType(item, 'filter')));
    this.sort$ = this._sort$.asObservable();
    this.sortMapped$ = this._sort$.pipe(map((item) => this._mapToType(item, 'sort')));
    this.start$ = combineLatest([this.page$, this.pageLimit$]).pipe(
      map(([page, limit]) => (page && limit ? page * limit : null))
    );
  }

  public setState(state: TmGrid.grid.State): void {
    this._store({
      page: state.page,
      pageLimit: state.pageLimit,
      sort: state.sort,
      filter: state.filter,
    });
  }

  public setPage(page: number): void {
    this._store({
      page: page,
    });
  }

  /**
   * use this page if no page restored from url
   */
  public setPageDefault(page: number): void {
    if (!this._page$.value) {
      this._store({
        page: page,
      });
    }
  }

  public setPageLimit(limit: number): void {
    this._store({
      pageLimit: limit,
    });
  }

  /**
   * use this page limit if no limit restored from url
   */
  public setPageLimitDefault(limit: number): void {
    if (!this._pageLimit$.value) {
      this._store({
        pageLimit: limit,
      });
    }
  }

  public setFilter(key: string, value: string | number | null | string[]) {
    if (value === '*' || value === '**') {
      value = null;
    }
    if (value && !Array.isArray(value)) {
      value = value.toString();
    }
    let newFilter = this._filter$.getValue() || {};
    newFilter = { ...newFilter, [key]: <string>value };

    this._store({
      filter: newFilter,
    });
  }

  public getFilterStateFor(param: string) {
    const currentFilter = this._filter$.value;
    if (currentFilter) {
      return currentFilter[param] || '';
    } else {
      return '';
    }
  }

  public getSort(): SortObject | null {
    return this._sort$.getValue() ? { ...this._sort$.getValue() } : null;
  }

  public setSort(sortModels: TmGrid.grid.SortModel[]) {
    let newSort = { ...(this._sort$.getValue() || {}) };
    Object.keys(newSort).forEach((field) => {
      newSort[field] = null;
    });

    sortModels.forEach((model) => {
      newSort[model.colId] = model.sort;
    });
    this._store({
      sort: newSort,
    });
  }

  /**
   * Restore state from first available storage
   */
  public restore(): void {
    if (this.storeKey === 'url') {
      this._restoreFromUrl();
    }
  }

  public clearUrlFromThisTableParams() {
    if (this.storeKey === 'url') {
      const sortParams = this._mapToType(this._sort$.value, 'sort', true);
      const filterParams = this._mapToType(this._filter$.value, 'filter', true);
      this._setNullToObjectProps(sortParams);
      this._setNullToObjectProps(filterParams);

      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        replaceUrl: true,
        queryParamsHandling: 'merge',
        queryParams: {
          [`${TmGridState.storeIdPrefix}${this._safeStoreId}`]: null,
          ...sortParams,
          ...filterParams,
        },
      });
    }
  }

  public ngOnDestroy(): void {
    this.clearUrlFromThisTableParams();
  }

  private _setNullToObjectProps(obj: FilterSortNullable): void {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        obj[key] = null;
      });
    }
  }

  private _mapToType(
    data: TmGrid.grid.FilterObject | TmGrid.grid.SortObject | null,
    type: 'sort' | 'filter',
    withStoreId = false
  ) {
    if (!data) {
      return null;
    }
    let mapped: any = {};
    Object.keys(data).forEach((key) => {
      data[key] = data[key] || null;
      if (key === 'query' && type === 'filter') {
        const keyToMap = this._safeStoreId && withStoreId ? `${this._safeStoreId}${key}` : `${key}`;
        mapped[keyToMap] = data[key];
        return;
      }

      if (withStoreId && this._safeStoreId) {
        if (Array.isArray(data[key])) {
          mapped[`${this._safeStoreId}${type}[${key}][]`] = data[key];
        } else {
          mapped[`${this._safeStoreId}${type}[${key}]`] = data[key];
        }
      } else {
        if (Array.isArray(data[key])) {
          mapped[`${type}[${key}][]`] = data[key];
        } else {
          mapped[`${type}[${key}]`] = data[key];
        }
      }
    });
    return mapped;
  }

  private _restoreFromUrl(): void {
    const _pageMatch = location.search.match(this._getPageRegexp);
    const sorts: SortObject | null = this._matchAll(decodeURIComponent(location.search), this._getSortRegexp);
    const filters: FilterObject | null = this._matchAll(decodeURIComponent(location.search), this._getFilterRegexp);

    if (_pageMatch) {
      const [, page, pageLimit] = _pageMatch;
      this._page$.next(parseInt(page, 10));
      this._pageLimit$.next(parseInt(pageLimit, 10));
    }

    this._sort$.next(sorts);
    this._filter$.next(filters);
  }

  private _matchAll(stringToMatch: string, regexp: RegExp): FilterSortNullable {
    let output: FilterSortNullable = null;
    let matches: RegExpExecArray | null = regexp.exec(stringToMatch);
    while (matches) {
      if (!output) {
        output = {};
      }
      if (matches[2] === '[]') {
        if (output[matches[1]]) {
          (output[matches[1]] as string[]).push(matches[3]);
        } else {
          output[matches[1]] = [matches[3]];
        }
      } else {
        output[matches[1]] = matches[2] || matches[3];
      }
      matches = regexp.exec(stringToMatch);
    }
    return output;
  }

  private _store(state: TmGrid.grid.State): void {
    // Keep in memory
    if (state.page !== null && state.page !== undefined) {
      this._page$.next(state.page);
    }
    if (state.pageLimit) {
      this._pageLimit$.next(state.pageLimit);
    }
    if (state.filter) {
      this._filter$.next(state.filter);
    }
    if (state.sort) {
      this._sort$.next(state.sort);
    }

    // Put in proper storage if any
    if (this.storeKey === 'url') {
      this._storeStateToUrl();
    }
  }

  private _storeStateToUrl(): void {
    const queryParams = {
      ...this._mapToType(this._sort$.value, 'sort', true),
      ...this._mapToType(this._filter$.value, 'filter', true),
    };
    if (this._page$.value !== null && this._pageLimit$.value) {
      queryParams[
        `${TmGridState.storeIdPrefix}${this._safeStoreId}`
      ] = `${this._page$.value},${this._pageLimit$.value}`;
    }

    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      queryParams: queryParams,
    });
  }
}
