import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Value, ValueItemExtractor, ValueType, Values } from './input-many.model';

@Injectable()
export class TmInputManyService {
  /**
   * Parsed value with leading user input value
   */
  public parsedValue$: Observable<Values> = of(null).pipe(
    switchMap(() => this._value$),
    map((value) => this._extractValues(value)),
    shareReplay(1)
  );

  /**
   * Current value (without empty leading user input value)
   */
  public value$: Observable<Values> = of(null).pipe(
    switchMap(() => this.parsedValue$),
    map((parsed) => this._removeLeadingEmptyValue(parsed))
  );

  private _value$: ReplaySubject<Values> = new ReplaySubject(1);

  private _valueItemExtractor: ValueItemExtractor;

  public appendValue(value: Value): void {
    this.parsedValue$.pipe(take(1)).subscribe((parsedValues) => {
      let nextValues = Array.from(parsedValues);
      nextValues.push(value);
      this.setValue(nextValues);
    });
  }

  public deleteValueByIndex(i: number): void {
    return this.splice(i, 1);
  }

  public editValueByIndex(valueIndex: number, value: Value): void {
    this.splice(valueIndex, 1, value);
  }

  /**
   * get length of all items in input
   * @param items
   */
  public getLengthByValues(items: Value[]): number {
    return items.reduce((sum, curr) => {
      sum += curr.length;
      return sum;
    }, 0);
  }

  /**
   * Acts the same way as Array.splice(), applied to input parsed data
   */
  public splice(start: number, length: number, value: Value | null = null) {
    this.parsedValue$.pipe(take(1)).subscribe((parsedValues) => {
      let nextValues = Array.from(parsedValues);
      value !== null ? nextValues.splice(start, length, value) : nextValues.splice(start, length);
      this.setValue(nextValues);
    });
  }

  /**
   * Set parsed value.
   * @param appendEmpty set true when value is initial (without leading user input value)
   */
  public setValue(value: Values, appendEmpty: boolean = false): void {
    const _value = Array.from(value);

    if (appendEmpty) {
      _value.push('');
    }

    this._value$.next(_value);
  }

  public setValueExtractor(type: ValueType | null): void {
    switch (type) {
      case 'text':
        this._valueItemExtractor = this._getStringValueToPush;
        break;
      case 'integer':
        this._valueItemExtractor = this._getIntValueToPush;
        break;
      default:
        this._valueItemExtractor = this._bypassValueExtractor;
        break;
    }
  }

  private _removeLeadingEmptyValue(parsed: Values): Values {
    if (!parsed.length) {
      return [];
    }

    if (!parsed[parsed.length - 1]) {
      return parsed.slice(0, -1);
    }

    return parsed;
  }

  private _extractValues(parsedValue: Values): Values {
    // Use Set to filter out doubles at low performance cost
    const resultSet: Set<Value> = new Set();
    let resultArray: Values;
    let lastExtractedItem;

    // Itarate over all items, except last one
    for (let valueIndex = 0; valueIndex < parsedValue.length - 1; valueIndex++) {
      lastExtractedItem = this._valueItemExtractor(parsedValue[valueIndex], false);
      if (lastExtractedItem !== null) {
        resultSet.add(lastExtractedItem);
      }
    }

    const lastValue: Value = parsedValue[parsedValue.length - 1] || '';
    resultArray = Array.from(resultSet);
    resultArray.push(this._valueItemExtractor(lastValue, true) || '');

    return resultArray;
  }

  private _getIntValueToPush(value: Value, leadingValue: boolean): Value | null {
    if (isNaN(parseInt(value, 10))) {
      return leadingValue ? '' : null;
    }

    const matched = value.match(/[\d]{1,}/);
    return matched![0];
  }

  private _getStringValueToPush(value: Value, leadingValue: boolean): Value | null {
    return value.trim() ? value : leadingValue ? value : null;
  }

  private _bypassValueExtractor(value: Value): Value {
    return value;
  }
}
