import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class TmAsyncValidatorsService {
  private _sessionValidationCache: any = {};

  constructor(private _http: HttpClient) {}

  /**
   * Get validator to check control on server(GPG-1677).
   * control.parent.getRawValue() is sent on server
   * @param dataTransformFn if server needs transformed data
   */
  public getAsyncObjectValidation(
    // eslint-disable-next-line
    resourceName:
      | 'visibilityArea'
      | 'user'
      | 'role'
      | 'tag'
      | 'ldapStatus'
      | 'resourceGroup'
      | 'resource'
      | 'perimetersGroup', // расширить по необходимости
    id$: Observable<any>,
    dataTransformFn = (data: any) => data
  ): AsyncValidatorFn {
    let initialValue: string | number;
    return (control: AbstractControl) => {
      if (control.parent) {
        // Don't check if initial value == to current (for items loaded from server)
        if (!initialValue) {
          initialValue = control.value;
        } else if (initialValue === control.value) {
          return of(null);
        }

        if (control.pristine) {
          return of(null);
        }
        // get control name
        const parentControls = control.parent.controls;
        const controlNameToGetErrors = Object.keys(parentControls).find(
          // @ts-ignore
          (controlName) => parentControls[controlName] === control
        )!;

        return timer(500).pipe(
          switchMap(() => id$),
          switchMap((id) => this._getRequestObj(resourceName, controlNameToGetErrors, control, dataTransformFn, id)),
          catchError((response: HttpErrorResponse) => of(response.error)),
          tap((data) => this._cacheValidationResponse(resourceName, controlNameToGetErrors, control.value, data)),
          map((data: any) =>
            data.meta ? data.meta[controlNameToGetErrors] || data.meta[controlNameToGetErrors.toUpperCase()] : null
          ),
          take(1)
        );
      } else {
        return of(null);
      }
    };
  }

  /**
   * Server validation of object
   * @param model data
   * @param resourceName name of resource, for example: visibilityArea, user
   * @param objId id of validated object
   */
  private _sendRequestToCheckModel(model: any, resourceName: string, objId: string | null): Observable<any> {
    if (!objId) {
      return this._http.post(`/api/${resourceName}/validate`, model);
    } else {
      return this._http.put(`/api/${resourceName}/${objId}/validate`, model);
    }
  }

  /**
   * get value from cache
   */
  private _tryToGetCachedValue(resourceName: string, controlName: string, value: any): Observable<any> | null {
    if (!value) {
      return null;
    }
    const cachedResponse = this._sessionValidationCache[`${resourceName}.${controlName}.${JSON.stringify(value)}`];
    return cachedResponse ? of(cachedResponse) : null;
  }

  /**
   * cache validation response
   */
  private _cacheValidationResponse(
    resourceName: string,
    controlName: string,
    value: any,
    responseFromServer: any
  ): void {
    if (value) {
      this._sessionValidationCache[`${resourceName}.${controlName}.${JSON.stringify(value)}`] = responseFromServer;
    }
  }

  /**
   * Get request or cached value
   */
  private _getRequestObj(
    resourceName: string,
    controlName: string,
    control: AbstractControl,
    dataTransformFn: (data: any) => any,
    id: string
  ): Observable<any> {
    return (
      this._tryToGetCachedValue(resourceName, controlName, control.value) ||
      this._sendRequestToCheckModel(dataTransformFn(control.parent?.getRawValue()), resourceName, id)
    );
  }
}
