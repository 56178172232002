import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  templateUrl: 'color-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorCellComponent implements AgRendererComponent {
  public color: string;

  public agInit(data: any): void {
    this._updateColor(data.value);
  }

  public refresh(data: any): boolean {
    this._updateColor(data.value);
    return true;
  }

  private _updateColor(color: string) {
    this.color = color;
  }
}
