import { ConfigDataGet, OldStatus, Status } from './generated/config';
import { TranslateService } from '@ngx-translate/core';

export function getFlagsByConfig(config: ConfigDataGet, t: TranslateService, isCurrentUser?: boolean) {
  const showButtons =
    (config.STATUS !== Status.FREE && isCurrentUser) ||
    config.STATUS === Status.SAVED ||
    config.OLD_STATUS === OldStatus.SAVED;

  const flags = {
    disableApplyReason: '',
    disableRevertReason: '',
    hideSaveButton: config.STATUS === Status.SAVED || config.OLD_STATUS === OldStatus.SAVED,
    disableApply: config.STATUS === Status.NEEDS_TRAINING,
    disableRevert: config.STATUS === Status.IS_TRAINING,
    showButtons,
    learningInProgress: config.STATUS === Status.IS_TRAINING,
  };

  if (flags.disableApply) {
    // @translate config.widget.title.image_classifier
    // @translate config.widget.title.autoling
    const tech = t.instant(`config.widget.title.${config.TECHNOLOGY}`);
    flags.disableApplyReason = t.instant('config.widget.title.needsTraining', { tech });
    return flags;
  }

  flags.disableApply = flags.disableRevert;
  if (flags.disableRevert) {
    const tech = t.instant(`config.widget.title.${config.TECHNOLOGY}`);
    flags.disableApplyReason = t.instant('config.widget.title.is_training', { tech });
    flags.disableRevertReason = t.instant('config.widget.title.is_training', { tech });
  }
  return flags;
}
