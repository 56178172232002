import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ExportModel, STATUSES } from '../export-monitor/export-monitor.model';
import { AVAILABLE_DOWNLOAD_TYPES, FormatFileModel } from './export-monitor-list-cell-status.model';
import { IwBytesWithUnitPipe } from '@platform/shared';

type ExportMonitorCellStatusParams = {
  data?: AnyKeysFrom<ExportModel>;
};

@Component({
  templateUrl: 'export-monitor-list-cell-status.component.html',
  styles: [
    `
      .spinner-inline {
        display: inline-block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMonitorCellStatusComponent implements ICellRendererAngularComp {
  public params?: ExportMonitorCellStatusParams;

  public STATUSES = STATUSES;

  constructor(
    private _translate: TranslateService,
    private sanitizer: DomSanitizer,
    private _bytesWithUnitPipe: IwBytesWithUnitPipe,
    private _cd: ChangeDetectorRef
  ) {}

  public agInit(params: ExportMonitorCellStatusParams): void {
    this.params = params;
    this._cd.detectChanges();
  }

  public refresh(params: ExportMonitorCellStatusParams): boolean {
    this.params = params;
    this._cd.detectChanges();

    return true;
  }

  public formatFiles(): FormatFileModel[] {
    let data = this.params && this.params.data;

    if (!data || !data.PARAMS) {
      return [];
    }

    let param = data.PARAMS[0];

    if (data.TYPE === 'dashboard') {
      return AVAILABLE_DOWNLOAD_TYPES.map((type) => {
        return {
          href: this._getUrlDashboard((data as ExportModel).HASH || '', type),
          text: type,
        };
      });
    } else {
      if (!data.REPORT_ID) {
        throw new Error('no report id');
      }

      return [
        {
          href: this._getUrlExport(data.REPORT_ID),
          text: `${this._bytesWithUnitPipe.transform(param.filesize)}.
        ${this._translate.instant('events.exportMonitor.download')}`,
        },
      ];
    }
  }

  private _getUrlDashboard(hash: string, type: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustUrl(`/public/${hash}.${type.toLowerCase()}`);
  }

  private _getUrlExport(id: string | number): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`/api/object/reportFile?report_id=${id}`);
  }
}
