import { Component } from '@angular/core';
import { TmElement } from '../../custom-elements';
import { ADDITIONAL_ITEMS, SearchParams } from '../tab-components/common';
import { TmeAbstractQueryComponent } from './tm-query-base.component';
import { WebResourcesTabComponent } from '../tab-components/web-resources-tab.component';
import { of } from 'rxjs';

@TmElement('tme-resources')
@Component({
  templateUrl: 'wrapper.html',
  styleUrls: ['wrapper-style.scss'],
})
export class TmeWebResoucesComponent extends TmeAbstractQueryComponent {
  additionalItems = of([ADDITIONAL_ITEMS.URL]);
  public components = [WebResourcesTabComponent];
  public params: SearchParams = {
    queryParams: {
      type: 'query',
      scopes: 'resource',
    },
  };
}
