import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabComponent } from './search-select.module';
import { ModalDialogRef } from '@platform/shared';
import { SearchParams } from './tab-components/common';

@Component({
  templateUrl: './search-tab-modal.component.html',
  styleUrls: ['./search-tab-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmSearchTabModalComponent {
  @Input() public selected: TmApi.scope.ConditionValueObject[] = [];
  @Input() public components: TabComponent[];
  @Input() tabParams: SearchParams['tabParams'];
  @Input() public modalHeader: string;

  @Output() public changed: EventEmitter<TmApi.scope.ConditionValueObject[]> = new EventEmitter();

  constructor(private ref: ModalDialogRef<TmSearchTabModalComponent>) {}

  public getItemsCount(component: TabComponent) {
    if ('count' in component) {
      return component.count(this.selected);
    }
    return this.selected.filter((item) => item.TYPE === component.scope).length || '';
  }

  public acceptChanges(): void {
    this.changed.emit(this.selected);
    this.ref.close(undefined);
  }
  public rejectChanges(): void {
    this.ref.dismiss(undefined);
  }
}
