import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IwIconService } from '@platform/shared';
import { TmStatefulService } from '@tm-shared/dataloader';
import { map } from 'rxjs/operators';

@Injectable()
export class TmBookwormContactTypeService extends TmStatefulService<
  TmApi.GetResponse<TmApi.ContactType.CollectionItem[]>
> {
  public src = '/api/bookworm/contactType?filter[SCOPE]=all';

  private _icons: { [key: string]: string } = {};
  constructor(protected http: HttpClient, private _iconService: IwIconService) {
    super(http);
  }

  public getIconNameByMnemo(mnemo: string): string {
    return this._icons[mnemo];
  }

  /**
   * @param scopesToGet get contact types for current scopes
   * @param mnemoToExclude exclude certain contact types
   */
  public getContactTypesFor(scopesToGet: TmApi.ContactType.Scopes[], ...mnemoToExclude: string[]) {
    return this.sharedData.pipe(
      map((response) => {
        return response.data.filter((item) => {
          return (
            item.scope.some((currentScope) => scopesToGet.includes(currentScope)) &&
            !mnemoToExclude.includes(item.mnemo)
          );
        });
      })
    );
  }

  protected deserialize(
    response: TmApi.GetResponse<TmApi.ContactType.CollectionItem[]>
  ): TmApi.GetResponse<TmApi.ContactType.CollectionItem[]> {
    this._cacheAndRegisterIcons(response.data);
    return response;
  }

  private _cacheAndRegisterIcons(contactTypes: TmApi.ContactType.CollectionItem[]) {
    const mnemo = 'meta';
    this._icons.meta = `${mnemo}.svg`;
    this._iconService.addSvgIcon(this._icons[mnemo], `/img/icons/${this._icons[mnemo]}`);

    contactTypes.forEach((item) => {
      this._icons[item.mnemo] = item.icon || item.mnemo + '.svg';
      this._iconService.addSvgIcon(this._icons[item.mnemo], `/img/icons/${this._icons[item.mnemo]}`);
    });
  }
}
