<div>
  <!-- <h2>{{ 'tm.systeminfo.title' | translate }}</h2> -->
  <div>
    <div>
      <img src="./tme-assets/ru-logo-about.png?8ff3e1775bb65fde14cd534c687fdc3b" />
    </div>
    <div data-qa="version" class="versionInfo">{{ 'tm.systeminfo.version' | translate }}: {{ version }}</div>
    <div class="productType">{{ 'tm.systeminfo.productType' | translate }}: {{ productType }}</div>
    <div class="contacts">
      <p class="phone">
        <label>{{ 'tm.systeminfo.phone' | translate }}:</label>
        <span>{{ phone }}</span>
      </p>
      <p class="email">
        <label>{{ 'tm.systeminfo.emailCommon' | translate }}:</label>
        <a href="mailto:{{ emailCommon }}">{{ emailCommon }}</a>
      </p>
      <p class="email">
        <label>{{ 'tm.systeminfo.emailSupport' | translate }}:</label>
        <a href="mailto:{{ emailSupport }}">{{ emailSupport }}</a>
      </p>
      <p class="email">
        <label>{{ 'tm.systeminfo.webpage' | translate }}:</label>
        <span>
          <a [href]="copyLink" target="_blank" rel="noreferrer noopener">{{ copyLinkText }}</a>
        </span>
      </p>
    </div>
  </div>
  <div class="copyright">
    <p class="copyrigthYear">© {{ yearFrom }}-{{ yearTo }} Infowatch</p>
    <p class="copyrightText">{{ 'tm.systeminfo.copyrights' | translate }}</p>
  </div>
</div>
