import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmConfigWidgetComponent } from './config-widget/config-widget.component';
import { TmConfigWidgetService } from './config-widget/config-widget.service';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';
import { TmSpinnerModule } from '../../@tm-shared/spinner';
import { TmPrivilegesModule } from '../../@tm-shared/privileges/privileges.module';

const ru = require('./i18n/config.ru.json');
const en = require('./i18n/config.en.json');

@NgModule({
  imports: [CommonModule, IwSharedModule, TmIconModule, TranslateModule, TmSpinnerModule, TmPrivilegesModule],
  exports: [TmConfigWidgetComponent],
  declarations: [TmConfigWidgetComponent],
  entryComponents: [TmConfigWidgetComponent],
  providers: [TmConfigWidgetService],
})
export class TmConfigElementsModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('ru', ru, true);
    this.translate.setTranslation('en', en, true);
  }
}
