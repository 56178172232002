import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { TmGridOptions } from '../../grid';
import { CheckboxCellComponent, ColorCellComponent } from '../../grid/cell-renderers';

@Component({
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusTabComponent extends TableTabComponent {
  public static scope = 'status' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.status';

  public scopeToEmitOnChange = StatusTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: this.scopeToEmitOnChange,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public options: TmGridOptions = {
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        width: 30,
        field: 'COLOR',
        headerName: '',
        cellRendererFramework: ColorCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
      },
      {
        field: 'NOTE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.note'),
      },
    ],
  };
}
