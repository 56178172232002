import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subject, BehaviorSubject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sqlStringToDateTime, isoToDateTime } from '@tm-shared/helpers/date';

@Directive({
  selector: '[tmLocalizeDate]',
})
export class LocalizeDateDirective implements OnInit, OnDestroy {
  @Input() public set tmLocalizeDate(input: string | Date | null | undefined) {
    if (this.inputValue.getValue() !== input) {
      this.inputValue.next(input);
    }
  }

  private inputValue = new BehaviorSubject<null | undefined | string | Date>(null);

  /**
   * Table of tokens: https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
   */
  @Input() public dateOutputFormat = 'f';

  /**
   * Table of tokens: https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
   */
  @Input() public dateInputFormat: 'SQL' | 'JSDate' | 'ISO' = 'SQL';

  private destroy: Subject<void> = new Subject();

  constructor(private el: ElementRef, private translateService: TranslateService) {}

  public ngOnInit(): void {
    merge(this.inputValue, this.translateService.onLangChange)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.updateDate());
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private updateDate(): void {
    (this.el.nativeElement as HTMLElement).innerHTML = this.getConvertedDate();
  }

  private getConvertedDate(): string {
    const value = this.inputValue.getValue();
    return value ? this.toDateTime(value).toFormat(this.dateOutputFormat) : '';
  }

  private toDateTime(input: string | Date): DateTime {
    if (this.dateInputFormat === 'SQL') {
      return sqlStringToDateTime(input as string);
    }

    if (this.dateInputFormat === 'ISO') {
      return isoToDateTime(input as string);
    }

    return DateTime.fromJSDate(input as Date);
  }
}
