import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { TmLdapGroupHierarchyApiService } from '@tm-shared/api-services';
import { AbstractTabComponent } from './abstract-tab-component';
import { TreeNode } from '@circlon/angular-tree-component';
import { ASYNC_ROOT, TmTreeComponent, TmTreeNodeData } from '../../tree';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: 'group-tab-without-deleted-group.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupTabWithoutDeletedGroupsComponent extends AbstractTabComponent implements AfterViewInit {
  public static scope = 'group' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.group';

  @ViewChild(TmTreeComponent) public tree: TmTreeComponent;

  public ngAfterViewInit() {
    const selected = this.selected.filter((item) => item.TYPE === 'group');
    this.tree.selectNodesByIds(selected.map((item) => item.DATA));
    this.setListeners();
  }

  private setListeners() {
    this.tree.onSelectionChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.emitChangedSelection();
    });
  }

  private emitChangedSelection() {
    const allItems = this.selected.filter((item) => item.TYPE !== 'group');
    allItems.push(...this.getSelectedInTree());
    this.selectedChange.emit(allItems);
  }

  private getSelectedInTree() {
    let selected = this.tree.getSelectedIds(true, true);
    const rootNode = this.tree.getNodeById(ASYNC_ROOT);
    if (rootNode?.isSelected) {
      selected = rootNode.children.map((item) => item.data.id);
    }
    return selected.map((id) => {
      const data = this.tree.getNodeById(id)?.data;
      return {
        TYPE: GroupTabWithoutDeletedGroupsComponent.scope,
        DATA: data.id,
        NAME: data.name,
      };
    });
  }

  constructor(public ldapGroupService: TmLdapGroupHierarchyApiService) {
    super();
  }

  /**
   * getChildren method for tm-tree getChildren input
   * sets logic for loading of lazy nodes
   */
  public getChildren = (node: TreeNode): Promise<TmTreeNodeData[]> => {
    return node.realParent
      ? this.ldapGroupService
          .get({
            params: {
              grouppath: node.data.ID_PATH || node.data.ID_NAME_PATH.ID_PATH,
            },
          })
          .pipe(map((response) => this._mapGroupToTreeNode(response.data)))
          .toPromise()
      : this.ldapGroupService
          .get()
          .pipe(map((response) => this._mapGroupToTreeNode(response.data)))
          .toPromise();
  };

  private _mapGroupToTreeNode(items: TmApi.ldapGroup.CollectionItem[]): TmTreeNodeData[] {
    return items.map((item) => {
      return {
        name: item.DISPLAY_NAME,
        hasChildren: !!item.childsCount,
        ID_PATH: item.ID_PATH || item.ID_NAME_PATH.ID_PATH,
        id: item.GROUP_ID,
      };
    });
  }
}
