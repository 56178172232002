import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: ` <tm-icon svgIcon="delete" size="18" (click)="onDelete()"></tm-icon> `,
  styles: [
    `
      :host {
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMonitorCellDeleteComponent implements ICellRendererAngularComp {
  public params: any;

  public agInit(params: any): void {
    this.params = params;
  }

  public refresh(params: any): boolean {
    this.params = params;

    return true;
  }

  public onDelete(): void {
    this.params.context.componentParent.onDeleteExport(this.params.node.data);
  }
}
