import { Injectable } from '@angular/core';
import { TmStatefulService } from '@tm-shared/dataloader';
import { map } from 'rxjs/operators';
import { ObjectHeaderInfo } from '../../typings/generated/bookworm';

@Injectable()
export class TmBookwormObjectHeaderService extends TmStatefulService<TmApi.GetResponse<ObjectHeaderInfo[]>> {
  public src = '/api/bookworm/objectHeaders';

  // NOTE === translation
  public getNoteByName(name: string) {
    return this.sharedData.pipe(
      map((response) => response.data.find((item) => item.NAME.toLowerCase() === name.toLowerCase())?.NOTE)
    );
  }
}
