import { Injectable } from '@angular/core';

@Injectable()
export class TmPolicyRuleService {
  public getEmptyObjectRules(): ApiPolicy.PolicyRuleObject[] {
    return [
      this.getEmptyRule('application'),
      this.getEmptyRule('copy'),
      this.getEmptyRule('placement'),
      this.getEmptyRule('transfer'),
      this.getEmptyRule('blockade'),
    ];
  }

  // eslint-disable-next-line
  public getEmptyAgentRules(): ApiPolicy.PolicyRuleAgent[] {
    return [this.getEmptyRule('copy'), this.getEmptyRule('transfer'), this.getEmptyRule('application')];
  }

  public getEmptyPersonRules(): ApiPolicy.PolicyRulePerson[] {
    return [this.getEmptyRule('person')];
  }

  public getEmptyRule(ruleType: 'application'): ApiPolicy.PolicyRuleApplication;
  public getEmptyRule(ruleType: 'copy'): ApiPolicy.PolicyRuleCopy;
  public getEmptyRule(ruleType: 'placement'): ApiPolicy.PolicyRulePlacement;
  public getEmptyRule(ruleType: 'transfer'): ApiPolicy.PolicyRuleTransfer;
  public getEmptyRule(ruleType: 'person'): ApiPolicy.PolicyRulePerson;
  public getEmptyRule(ruleType: 'blockade'): ApiPolicy.PolicyRuleBlockade;
  public getEmptyRule(ruleType: ApiPolicy.RuleType): any {
    const ruleCommonProperties: ApiPolicy.PolicyRuleCommon = {
      IS_SYSTEM: 1,
      DATA: {
        END_TIME: null,
        PluginAttributes: [],
        START_TIME: null,
      },
      actions: [],
    };

    switch (ruleType) {
      case 'application':
      case 'copy':
      case 'placement':
      case 'transfer':
      case 'person':
      case 'blockade':
        return Object.assign(ruleCommonProperties, {
          TYPE: ruleType,
        });
      default:
        throw new Error('unknown policy rule type');
    }
  }
}
