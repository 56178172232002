import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IwIconService } from '@platform/shared';
import {
  TmBookwormActionsService,
  TmBookwormContactTypeService,
  TmBookwormEventsService,
  TmBookwormFormatTypesService,
  TmBookwormFormatsService,
  TmBookwormServicesService,
} from '@tm-shared/bookworm';
import { TmBrowserTabSyncService } from '@tm-shared/browser-tab-sync/browser-tab-sync.service';
import { TmChannelService } from '@tm-shared/channel';
import { TmHealthService } from '@tm-shared/health/health.service';
import { TmIdlerService } from '@tm-shared/idler';
import { TmLicenseService } from '@tm-shared/license';
import { TmOptionsService } from '@tm-shared/options';
import { TmPrivilegesService } from '@tm-shared/privileges';
import { TmSessionService } from '@tm-shared/session';
import { TmSystemInfoService } from '@tm-shared/systeminfo';
import { DateTime, Settings as LuxonSettings } from 'luxon';
import { combineLatest, merge, of } from 'rxjs';
import { map, mapTo, skip, switchMap, take } from 'rxjs/operators';
import { TmCommonErrorsInterceptor } from './tm-errors.interceptor';
import en from './i18n/tm.en.json';
import ru from './i18n/tm.ru.json';
import { cookies } from '@tm-shared/helpers';
import { COMMON_TM_ICONS } from '../../typings/generated/icons';
import { TmCustomElementsService } from '../../@tm-shared/custom-elements';

@Injectable()
export class TmService {
  constructor(
    private _t: TranslateService,
    private _healthService: TmHealthService,
    private _commonBackendErrors: TmCommonErrorsInterceptor,
    private _idler: TmIdlerService,
    private readonly _options: TmOptionsService,
    private _iconService: IwIconService,
    private _session: TmSessionService,
    private _channelService: TmChannelService,
    private _licenseService: TmLicenseService,
    private _privilegesService: TmPrivilegesService,
    private _systemInfoService: TmSystemInfoService,
    private _tabSync: TmBrowserTabSyncService,
    private _actionService: TmBookwormActionsService,
    private _eventsService: TmBookwormEventsService,
    private _formatTypesService: TmBookwormFormatTypesService,
    private _formatService: TmBookwormFormatsService,
    private _servicesService: TmBookwormServicesService,
    private _contactTypesService: TmBookwormContactTypeService,
    _elements: TmCustomElementsService // tme- webcomponents registration inside
  ) {}

  public bootstrapTm(): void {
    this._options.set(TM_CONFIG);

    this._setupLocalization();
    this._registerSvgIcons(COMMON_TM_ICONS);
    this._setupCrossServiceInteractions();
    this._session.restoreSession();
    this._setupIdler();
  }

  private _setupLocalization(): void {
    /**
     * Set available languages
     */
    this._options.getWithUpdates('availableLanguages').subscribe((availableLangs) => {
      this._t.addLangs(availableLangs);
    });

    /**
     * Register tm language files
     */
    this._t.setTranslation('en', en, true);
    this._t.setTranslation('ru', ru, true);

    /**
     * Set default language.
     */
    this._options.getWithUpdates('defaultLanguage').subscribe((lang) => {
      this._t.setDefaultLang(lang);
    });

    /**
     * Set current language.
     * Try to use previous language selection or fallback to default one.
     */
    this._t.use(this.getInitialLanguage(this._t.defaultLang));

    /**
     * Listen to current language selection
     */
    this._t.onLangChange.subscribe(({ lang }: LangChangeEvent) => {
      LuxonSettings.defaultLocale = lang;
      this._refreshBookworm();
    });
  }

  private getInitialLanguage(fallbackLanguage: string): string {
    return cookies.getByName('language')?.slice(0, 2) || fallbackLanguage;
  }

  private _refreshBookworm(): void {
    this._actionService.refresh().subscribe();
    this._eventsService.refresh().subscribe();
    this._formatTypesService.refresh().subscribe();
    this._formatService.refresh().subscribe();
    this._servicesService.refresh().subscribe();
    this._contactTypesService.refresh().subscribe();
  }

  private _setupCrossServiceInteractions(): void {
    // Start health service notifications
    this._healthService.notifyOnError(true);

    // Report common errors to HealthService
    this._commonBackendErrors.localizedError$.subscribe(([key, title, text]) => {
      this._healthService.setError(key, title, text);
    });

    /**
     * Listen to login state
     */
    this._session.isLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this._channelService.stop();
      }
    });

    /**
     * Listen to session
     */
    this._session.session$.subscribe((session) => {
      this._channelService.start(session.CHANNEL_NAME);
      this._privilegesService.resetPrivileges(session.privileges);
    });

    /**
     * Listen to USER socket messages
     */
    this._channelService.getUserChannel('user').subscribe((data) => {
      this._session.updateBySocketMessage(data);
    });

    /**
     * Listen to ROLE socket messages
     */
    this._channelService.getUserChannel('role').subscribe((data) => {
      this._privilegesService.updateBySocketMessage(data);
    });

    /** Listen to LICENSE socket messages */
    this._channelService.getUserChannel('license').subscribe((data) => {
      this._licenseService.updateBySocketMessage(data);
    });

    /**
     * Setup session logout by idler
     */
    this._idler.timeout$.pipe(switchMap(() => this._session.logout())).subscribe();

    /**
     * Push to tabSync on userId change
     */
    this._session.userId$.subscribe((userId) => {
      userId === null ? this._tabSync.remove('userId') : this._tabSync.set('userId', userId.toString());
    });

    this._tabSync
      .listen('userId')
      .pipe(
        skip(1),
        switchMap((tabUserId) =>
          this._session.loginState$.pipe(
            switchMap((state) => (!state ? of(null) : this._session.userId$)),
            take(1),
            map((sessionUserId) => [tabUserId, sessionUserId === null ? undefined : sessionUserId.toString()])
          )
        )
      )
      .subscribe(([tabUserId, sessionUserId]: (string | undefined)[]) => {
        if (tabUserId !== sessionUserId) {
          if (tabUserId === undefined) {
            location.reload();
          } else {
            this._session.restoreSession();
          }
        }
      });
  }

  /**
   * Register icons in the global namespace of IwIconService
   */
  private _registerSvgIcons(iconMap: typeof COMMON_TM_ICONS): void {
    Object.keys(iconMap).forEach((iconName: keyof typeof COMMON_TM_ICONS) => {
      this._iconService.addSvgIcon(iconName, iconMap[iconName]);
    });
  }

  private _setupIdler(): void {
    // TabSync by this key
    const idlerSyncResetKey = 'idlerSyncReset';

    // Trigger reset
    this._idler.reset$.subscribe(() => this._tabSync.set(idlerSyncResetKey, DateTime.local().toFormat('X')));

    // Reset by tabSync event, skip when triggered by itself
    merge(this._idler.reset$.pipe(mapTo(1)), of(0))
      // Skip 'skipTimes + 1', because we do not want to reset by initial value and first update
      .pipe(switchMap((skipTimes) => this._tabSync.listen(idlerSyncResetKey).pipe(skip(1 + skipTimes))))
      .subscribe(() => this._idler.reset(true));

    // Setup start/stop logic
    combineLatest([this._session.isLoggedIn$, this._systemInfoService.getTimeout()]).subscribe(([isLoggedIn, ms]) =>
      isLoggedIn && ms > 0 ? this._idler.start(ms) : this._idler.stop()
    );
  }
}
