import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { TmGridOptions } from '../../grid';

@Component({
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkstationTabComponent extends TableTabComponent {
  public static scope = 'workstation' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.workstation';

  public scopeToEmitOnChange = WorkstationTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: this.scopeToEmitOnChange,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public options = <TmGridOptions>{
    sortingOrder: ['desc', 'asc'],
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sortable: true,
        sort: 'asc',
        resizable: true,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.fieldNames.displayName'),
      },
      {
        field: 'SOURCE',
        sortable: true,
        resizable: true,
        width: 100,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapPerson.source'),
        valueFormatter: (params: ValueFormatterParams) => (params.value ? params.value.toUpperCase() : ''),
      },
      {
        resizable: true,
        valueGetter: (params: ValueGetterParams) => {
          return params.data.real_server && params.data.DNSHOSTNAME && params.data.DNSHOSTNAME.replace(/^[^\.]*\./, '');
        },
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapPerson.server'),
      },
      {
        resizable: true,
        valueGetter: (params: ValueGetterParams) => {
          return params.data.real_server && params.data.real_server.DISPLAY_NAME;
        },
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapPerson.ldapServer'),
      },
    ],
  };
}
