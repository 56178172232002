import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

type ExportMonitorCellNameComponentParams = {
  data?: { DISPLAY_NAME?: string; TYPE?: string };
};

@Component({
  templateUrl: 'export-monitor-list-cell-name.component.html',
  styleUrls: ['export-monitor-list-cell-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMonitorCellNameComponent implements ICellRendererAngularComp {
  public params?: ExportMonitorCellNameComponentParams;

  constructor(private _cd: ChangeDetectorRef) {}

  public agInit(params: ExportMonitorCellNameComponentParams): void {
    this.params = params;
    this._cd.detectChanges();
  }

  public refresh(params: ExportMonitorCellNameComponentParams): boolean {
    this.params = params;
    this._cd.detectChanges();

    return true;
  }
}
