export const DEFINITIONS = {
  b: { validator: '[01]', cardinality: 1 },
  // three
  t: {
    validator: '[0-2]',
    cardinality: 1,
  },

  // quatro
  q: {
    validator: '[0-3]',
    cardinality: 1,
  },
  f: {
    validator: '[BbFfLlSsWw]',
    cardinality: 1,
    casing: 'upper',
  },

  // user
  u: {
    // eslint-disable-next-line
    validator: "[a-zA-Zа-яёА-ЯЁ0-9!{}#$%&*+/=?^_'|~\\-.]",
    cardinality: 1,
  },

  // domain
  d: {
    validator: '[\\[\\]а-яёА-ЯЁa-zA-Z0-9_\\-.]',
    cardinality: 1,
  },
  o: {
    validator: '[0-9A-NP-Za-np-z]',
    cardinality: 1,
    casing: 'upper',
  },
  e: {
    validator: '(AAA|BBB|CCC|EEE|XXX|ААА|ВВВ|ССС|ЕЕЕ|ХХХ|AAa|BBb|CCc|EEe|XXx|ААа|ВВв|ССс|ЕЕе|ХХх)',
    cardinality: 3,
    casing: 'upper',
    prevalidator: [
      {
        validator: '[ABCEXАВСЕХabcexавсех]',
        cardinality: 1,
      },
    ],
  },

  i: {
    validator: '[Ii]',
    cardinality: 1,
    casing: 'upper',
  },
  v: {
    validator: '[Vv]',
    cardinality: 1,
    casing: 'upper',
  },
  x: {
    validator: '[XxХх]',
    cardinality: 1,
    casing: 'upper',
  },
  // uppercased alpha
  A: {
    validator: '[A-Za-z]',
    cardinality: 1,
    casing: 'upper',
  },
  R: {
    validator: '[I|1|V|У|X|Х|L|Л|M|М|C|С|i|1|v|у|x|х|l|л|m|м|c|с]',
    cardinality: 1,
    casing: 'upper',
  },
  // cyrillic alpha
  а: {
    validator: '[А-ЯЁа-яё]',
    cardinality: 1,
    casing: 'upper',
  },
  '/': {
    validator: '[0-9A-Za-z*XxХх]',
    cardinality: 1,
  },
  1: {
    validator: '[2-9A-Za-z]',
    cardinality: 1,
    casing: 'upper',
  },
  5: {
    validator: '[1-5]',
    cardinality: 1,
    casing: 'upper',
  },
  0: {
    validator: '[0-9 ―\\-.]',
    cardinality: 1,
  },
  '―': {
    validator: '[―\\-]',
    cardinality: 0,
  },
  '٩': {
    validator: '[0-9٠١٢٣٤٥٦٧٨٩]',
    cardinality: 1,
  },
} as const;
