import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TmBookwormFormatsService } from '../../bookworm';
import { IwBytesWithUnitPipe } from '@platform/shared';
import { FingerprintTabComponent } from './fingerprint-tab.component';
import { TmGridOptions } from '../../grid';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { sqlStringToDateTime } from '../../helpers/date';

@Component({
  templateUrl: './fingerprint-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnloadTableTabComponent extends FingerprintTabComponent {
  public static scope = 'table' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.tables';

  public scopeToEmitOnChange = UnloadTableTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: UnloadTableTabComponent.scope,
  };

  public options = <TmGridOptions>{
    sortingOrder: ['desc', 'asc'],
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this.t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
      },
      {
        field: 'MIME',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this.t.instant('@tm-shared.search-select.headerNames.fingerprint.mime'),
        valueFormatter: (params) => {
          return this.formats && params.value ? this.formats[params.value] : '';
        },
      },
      {
        field: 'SOURCE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this.t.instant('@tm-shared.search-select.headerNames.table.source'),
        valueFormatter: (params) => {
          return params.value === 'user'
            ? this.t.instant('@tm-shared.search-select.headerNames.table.sourceVal.user')
            : this.t.instant('@tm-shared.search-select.headerNames.table.sourceVal.auto');
        },
      },
      {
        field: 'FILE_PATH',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this.t.instant('@tm-shared.search-select.headerNames.fingerprint.file_path'),
      },
      {
        field: 'FILE_SIZE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this.t.instant('@tm-shared.search-select.headerNames.fingerprint.file_size'),
        valueFormatter: (params) => (params.value ? this.bytesWithUnitPipe.transform(params.value) : ''),
      },
      {
        field: 'CREATE_DATE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this.t.instant('@tm-shared.search-select.headerNames.fingerprint.createDate'),
        valueFormatter: (params) => {
          return params.value ? sqlStringToDateTime(params.value).toFormat('f') : '';
        },
      },
      {
        field: 'NOTE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this.t.instant('@tm-shared.search-select.headerNames.ldapStatus.note'),
      },
    ],
  };

  constructor(
    service: TmSearchService,
    private t: TranslateService,
    formatsService: TmBookwormFormatsService,
    private bytesWithUnitPipe: IwBytesWithUnitPipe,
    cd: ChangeDetectorRef
  ) {
    super(service, t, formatsService, bytesWithUnitPipe, cd);
  }
}
