<div
  #dropdown="iwPopover"
  [ngClass]="{ button: true, isOpened: dropdown.isVisible | async }"
  [iwPopover]="exportMonitor"
  container="body"
  #exportMonitorButton
  [hidden]="!(itemsCount | async)"
>
  <div>
    <tm-icon *ngIf="complete" svgIcon="successWhite" style="margin-right: 10px;"></tm-icon>
    <tm-spinner *ngIf="loadingNewLength | async" size="16px" style="display: inline-block;"></tm-spinner>
  </div>
  {{ 'events.menu.export' | translate }}
  <span *ngIf="loadingNewLength | async as len" class="count">{{ len }}</span>
  <i class="caret"></i>
</div>

<ng-template #exportMonitor>
  <div class="exportMonitor dropdown">
    <header>
      <h2>{{ 'events.exportMonitor.title' | translate }}</h2>
      <p style="margin-bottom: 1em;">{{ 'events.exportMonitor.export_store_time_note' | translate }}</p>
    </header>
    <div>
      <tm-export-monitor-list></tm-export-monitor-list>
    </div>
  </div>
</ng-template>
