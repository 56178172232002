import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmStatefulService } from '@tm-shared/dataloader/stateful.service';
import { UrlStreams } from '@tm-shared/url-streams';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

const DEFAULT_IDLER_TIMEOUT_MS = 1800000;

@Injectable()
export class TmSystemInfoService extends TmStatefulService<TmApi.GetResponse<TmShared.systeminfo.Model>> {
  public readonly src = UrlStreams.create('/api/setting');

  constructor(_http: HttpClient, private _t: TranslateService) {
    super(_http);
  }

  public getTimeout(defaultValue: number = DEFAULT_IDLER_TIMEOUT_MS): Observable<number> {
    return this.sharedData.pipe(
      map((response) => {
        let timeout;
        if (response.data.web_logout_timeout) {
          timeout = parseFloat(response.data.web_logout_timeout);
        }
        timeout = timeout && !isNaN(timeout) && timeout >= 0 ? timeout * 1000 : defaultValue;
        return timeout;
      })
    );
  }

  public getLocalizedProductVersion(): Observable<string> {
    return this.sharedData.pipe(
      take(1),
      /**
       * @translate @tm-shared.systeminfo.enterprise
       * @translate @tm-shared.systeminfo.standard
       */
      map((response) => response.data),
      switchMap((data) => this._t.get(`@tm-shared.systeminfo.${data.product === 'tme' ? 'enterprise' : 'standard'}`))
    );
  }
}
