import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IwNotificationsService } from '@platform/shared';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ExportMonitorCellDateComponent } from '../export-monitor-list-cell-date/export-monitor-list-cell-date.component';
import { ExportMonitorCellDeleteComponent } from '../export-monitor-list-cell-delete/export-monitor-list-cell-delete.component';
import { ExportMonitorCellStatusComponent } from '../export-monitor-list-cell-status/export-monitor-list-cell-status.component';
import { ExportModel, STATUSES } from '../export-monitor/export-monitor.model';
import { TmExportMonitorService } from '../export-monitor/export-monitor.service';
import { ExportMonitorCellNameComponent } from '../export-monitor-list-cell-name/export-monitor-list-cell-name.component';

@Component({
  selector: 'tm-export-monitor-list',
  templateUrl: 'export-monitor-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMonitorListComponent implements OnInit, OnDestroy {
  public compareStatuses = {
    [STATUSES.NEW]: 3,
    [STATUSES.EXECUTING]: 2,
    [STATUSES.COMPLETE]: 1,
    [STATUSES.ERROR]: 0,
  };

  public style = {
    width: '100%',
    height: '100%',
    'max-height': 'calc(100vh - 155px)',
  };

  public columnDefs: any[];
  public gridOptions: GridOptions;

  private _unsubscribe: Subject<void> = new Subject();

  constructor(
    private _translate: TranslateService,
    private _exportService: TmExportMonitorService,
    private _cdr: ChangeDetectorRef,
    private _notify: IwNotificationsService
  ) {}

  public ngOnInit() {
    this.gridOptions = <GridOptions>{
      suppressContextMenu: true,
      enableColResize: true,
      suppressMovableColumns: true,
      suppressCellSelection: true,
      toolPanelSuppressSideButtons: true,
      rowSelection: 'multiple',
      context: {
        componentParent: this,
      },
      onGridReady: (params) => {
        params.api.sizeColumnsToFit();
        this._initTable();
      },
    };

    this.columnDefs = [
      {
        headerValueGetter: () => this._translate.instant('events.exportMonitor.export_name'),
        width: 200,
        suppressMenu: true,
        cellRendererFramework: ExportMonitorCellNameComponent,
      },
      {
        headerValueGetter: () => this._translate.instant('events.exportMonitor.export'),
        width: 200,
        field: 'STATUS',
        suppressMenu: true,
        comparator: (
          val1: number,
          val2: number,
          { data: data1 }: { data: ExportModel },
          { data: data2 }: { data: ExportModel }
        ) => {
          let comparator = this.compareStatuses[val1] - this.compareStatuses[val2];

          if (comparator > 0) {
            return 1;
          }
          if (comparator < 0) {
            return -1;
          }

          if (val1 === STATUSES.EXECUTING) {
            return data1.PERCENT_COMPLETE - data2.PERCENT_COMPLETE;
          }

          return 0;
        },
        cellRendererFramework: ExportMonitorCellStatusComponent,
      },
      {
        headerValueGetter: () => this._translate.instant('events.exportMonitor.start_and_end_date'),
        width: 200,
        field: 'CREATE_DATE',
        suppressMenu: true,
        sort: 'desc',
        cellRendererFramework: ExportMonitorCellDateComponent,
      },
      {
        headerName: '',
        width: 30,
        field: 'delete',
        suppressMenu: true,
        cellRendererFramework: ExportMonitorCellDeleteComponent,
      },
    ];
  }

  public ngOnDestroy() {
    this._unsubscribe.next();
  }

  public onDeleteExport(rowData: ExportModel): void {
    this._exportService
      .remove(rowData.REPORT_ID)
      .pipe(
        catchError((err) => {
          this._notifyOnError(err);
          return throwError(err);
        })
      )
      .subscribe();
  }

  private _notifyOnError(err: HttpErrorResponse) {
    this._notify.error(this._translate.instant('events.exportMonitor.export'), err.error);
  }

  private _initTable(): void {
    this._exportService.sharedData.pipe(takeUntil(this._unsubscribe)).subscribe((rowData) => {
      // Calculate list height
      this.style.height = `${46 * rowData.length + 26}px`; // rowHeight * numberOfRows + headerHeight
      this._cdr.detectChanges();

      (this.gridOptions.api as GridApi).setRowData(rowData);
    });
  }
}
