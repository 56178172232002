import { Injectable } from '@angular/core';
import { CategoryListItem } from '../../../typings/generated/technology-category';
import ConditionValueObject = TmApi.scope.ConditionValueObject;
import { CategoryTabComponent } from './category-tab.component';
import { TmTreeNodeData } from '../../tree';
type ChildNode = TmTreeNodeData & { parentId?: string };

@Injectable()
export class CategoryTabService {
  public mapParentToChildren(items: CategoryListItem[] | undefined, selectedAll: ConditionValueObject[]) {
    const selected = selectedAll.filter((item) => item.TYPE === CategoryTabComponent.scope).map((item) => item.DATA);

    if (items) {
      const list: ChildNode[] = items.map((item) => {
        return {
          name: item.DISPLAY_NAME,
          id: item.CATEGORY_ID,
          children: <ChildNode[]>[],
          deleted: item.IS_DELETED,
          isSelected: selected.includes(item.CATEGORY_ID),
          isExpanded: true,
          parentId: item.PARENT_CATEGORY_ID,
        };
      });
      for (let i = 0; i < list.length; i++) {
        if (list[i].parentId) {
          const childNode = list.splice(i, 1)[0];
          i--;
          this.setChildNodeToParent(list, childNode);
        }
      }

      this.selectAllChildrenForSelectedNodes(list);
      return list;
    }

    return [];
  }

  private setChildNodeToParent(list: ChildNode[] | undefined, node: ChildNode) {
    list?.forEach((listItem) => {
      if (listItem.id === node.parentId) {
        listItem.children?.push(node);
        return;
      } else {
        this.setChildNodeToParent(listItem.children, node);
      }
    });
  }

  private selectAllChildrenForSelectedNodes(list: ChildNode[]) {
    list.forEach((node) => {
      if (node.children) {
        if (node.isSelected) {
          this.selectAllChildren(node.children);
        } else {
          this.selectAllChildrenForSelectedNodes(node.children);
        }
      }
    });
  }

  private selectAllChildren(list: ChildNode[]) {
    list.forEach((node) => {
      node.isSelected = true;
      if (node.children) {
        this.selectAllChildren(node.children);
      }
    });
  }
}
