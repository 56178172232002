import { Input, Output, EventEmitter, OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractTabComponent implements OnDestroy {
  public destroy$ = new Subject();

  @Input() tabParams: any;
  @Input() public selected: TmApi.scope.ConditionValueObject[];
  @Output() public selectedChange = new EventEmitter<TmApi.scope.ConditionValueObject[]>();

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
