import { AfterViewInit, Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TmGridComponent } from './tm-grid.component';
import { map as mapLodash } from 'lodash';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[tmGridSelectFilter]',
})
export class GridSelectFilterDirective implements AfterViewInit, OnDestroy {
  @Input('tmGridSelectFilter') public tableToFilter: TmGridComponent<any>;
  @Input('formControl') public filter: FormControl;
  /**
   * api model attribute or any special
   */
  @Input() public propertyToFilter: string;

  private _destroy$ = new Subject();

  public ngAfterViewInit(): void {
    if (this.tableToFilter) {
      this.tableToFilter
        .getFilterStateChange(this.propertyToFilter)
        .pipe(takeUntil(this._destroy$))
        .subscribe((state) => this._restoreSelectState(state));
    } else {
      // для исправления ошибки использовать либо ссылки # в шаблоне, либо корректно настроить viewchild.
      // Так же возможен вариант отрисовки поля раньше, чем грида - тогда синхронизировать отрисовку.
      throw Error('NO GRID TO FILTER');
    }
  }

  public ngOnDestroy(): void {
    this.filter.setValue(null);
    this.tableToFilter.updateFilter(this.propertyToFilter, '');
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('changed', ['$event'])
  public _onChanged(event: IwSelectItem[]) {
    this.tableToFilter.updateFilterAndRefresh(this.propertyToFilter, mapLodash(event, 'value'));
  }

  private _restoreSelectState(state: string | string[] | null) {
    this.filter.setValue(state);
  }
}
