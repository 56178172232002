<div *ngIf="showOnPage" class="configPanel">
  <div class="configState">
    <tm-spinner class="widget-spinner" *ngIf="learningInProgress"></tm-spinner>
    <span>{{ localConfigurationStatusText }}</span>
    <span>{{ training }}</span>

    <button
      iw-button
      *ngIf="showButtons"
      [disableClickEventOnly]="disableApply"
      [iwPopover]="disableApplyReason | translate"
      type="button"
      class="widget-button"
      (click)="openConfigModal('apply')"
    >
      {{ 'config.widget.local.apply' | translate }}
    </button>
    <button
      iw-button
      *ngIf="showButtons && !hideSaveButton"
      type="button"
      class="widget-button"
      (click)="openConfigModal('save')"
    >
      {{ 'config.widget.local.save' | translate }}
    </button>
    <button
      iw-button
      *ngIf="showButtons"
      [disableClickEventOnly]="disableRevert"
      [iwPopover]="disableRevertReason | translate"
      type="button"
      class="widget-button"
      (click)="openConfigModal('revert')"
    >
      {{ 'config.widget.local.revert' | translate }}
    </button>
    <span>{{ lastEdited }}</span>
    <span *ngIf="localConfig">{{
      'config.widget.title.version' | translate: { version: localConfig.config.CHANGE_ID }
    }}</span>
  </div>
</div>
