import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, ExistingProvider } from '@angular/core';
import {
  TmAdlibitumServerService,
  TmLdapGroupHierarchyApiService,
  TmLdapPersonApiService,
  TmLdapStatusApiService,
  TmLdapUserSearchService,
  TmPolicyApiService,
  TmProtectedCatalogApiService,
  TmProtectedObjectApiService,
  TmResourcesGroupService,
  TmResourcesService,
  TmRoleApiService,
  TmScopeApiService,
  TmSearchService,
  TmTagApiService,
  TmUserApiService,
  TmPerimetersGroupService,
  TmPerimetersPersonsService,
  TmPerimetersGroupsService,
  TmPerimetersSystemListService,
  TmPerimetersWebContactService,
  TmPerimetersContactsService,
} from './api-services';
import {
  TmBookwormActionsService,
  TmBookwormContactTypeService,
  TmBookwormEventsService,
  TmBookwormFormatTypesService,
  TmBookwormFormatsService,
  TmBookwormProtocolsService,
  TmBookwormServicesService,
} from './bookworm';
import { TmBrowserTabSyncService } from './browser-tab-sync/browser-tab-sync.service';
import { TmChannelService } from './channel';
import { TmCustomElementsService } from './custom-elements';
import { TmDescriptionModule } from './description/description.module';
import { TmFillerTextComponent } from './filler-text/filler-text.component';
import { TmFormErrorsService } from './form';
import { TmTumblerIndicatorComponent } from './form-tumbler-indicator/tumbler-indicator.component';
import { TmIdlerService } from './idler';
import { TmEventMigrationService } from './legacy-container/events-migration.service';
import { TmLicenseService } from './license';
import { TmLocalStorageService } from './localstorage';
import { TmOptionsService } from './options';
import { TmOverlayModule } from './overlay';
import { TmPanelComponent } from './panel';
import { TmPolicyModule } from './policy/policy.module';
import { TmPrivilegesService } from './privileges';
import { TmSessionService } from './session';
import { TmSpinnerModule } from './spinner/spinner.module';
import { TmSystemInfoService } from './systeminfo';
import { TmTechLoaderComponentService } from './tech-loader/tech-loader-component.service';
import { TmbbTechLoaderService } from './tech-loader/tech-loader-tmbb.service';
import { TmVersionService } from './version';
import { IwSharedModule } from '@platform/shared';
import { TmPluginService } from './plugin/plugin.service';
import { TmIconModule } from './icons/tm-icon.module';
import { TmBookwormObjectHeaderService } from './bookworm/bookworm-object-header.service';

/**
 * Core singleton services
 */
const PROVIDERS: { [key: string]: Provider } = {
  TmBookwormObjectHeaderService,
  TmBookwormActionsService,
  TmBookwormContactTypeService,
  TmBookwormEventsService,
  TmBookwormFormatTypesService,
  TmBookwormFormatsService,
  TmBookwormProtocolsService,
  TmBookwormServicesService,
  TmChannelService,
  TmFormErrorsService,
  TmLicenseService,
  TmLocalStorageService,
  TmOptionsService,
  TmPrivilegesService,
  TmSessionService,
  TmCustomElementsService,
  TmSystemInfoService,
  TmVersionService,
  TmUserApiService,
  TmScopeApiService,
  TmRoleApiService,
  TmTagApiService,
  TmLdapUserSearchService,
  TmAdlibitumServerService,
  TmProtectedObjectApiService,
  TmLdapStatusApiService,
  TmProtectedCatalogApiService,
  TmLdapPersonApiService,
  TmSearchService,
  TmLdapGroupHierarchyApiService,
  TmBrowserTabSyncService,
  TmIdlerService,
  TmPolicyApiService,
  TmResourcesGroupService,
  TmResourcesService,
  TmPerimetersGroupService,
  TmPerimetersPersonsService,
  TmPerimetersSystemListService,
  TmPerimetersWebContactService,
  TmPerimetersContactsService,
  TmPerimetersGroupsService,
  TmTechLoaderComponentService,
  TmbbTechLoaderService,
  TmEventMigrationService,
  TmPluginService,
};

/**
 * @legacy it was used to provide compatibility with the platform/core
 * @deprecated please do not use stringified tokens, instead use original class-based tokens
 */
const NAMED_PROVIDERS: ExistingProvider[] = Object.keys(PROVIDERS).map((key: string) => ({
  provide: key,
  useExisting: PROVIDERS[key],
}));

export const providers = [...Object.values(PROVIDERS), ...NAMED_PROVIDERS];

/**
 * Core services, * should be exported once by TM plugin;
 */
@NgModule({
  imports: [
    IwSharedModule,
    TmOverlayModule,
    TmSpinnerModule,
    TmPolicyModule,
    TmIconModule,
    TmDescriptionModule,
    CommonModule,
  ],
  declarations: [TmPanelComponent, TmTumblerIndicatorComponent, TmFillerTextComponent],
  exports: [TmPanelComponent, TmTumblerIndicatorComponent, TmFillerTextComponent, TmIconModule],
})
export class TmSharedModule {
  /**
   * Call it once in main plugin (tm.module)
   */
  public static forRoot(): ModuleWithProviders<TmSharedModule> {
    return {
      ngModule: TmSharedModule,
      providers: providers,
    };
  }
}
