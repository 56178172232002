// Генерируется командой npm run icons:sync  на основе иконок лежащих в папке src/assets/icons/svg

import checkingIcon from '../../assets/icons/svg/checking.svg';
import closeIcon from '../../assets/icons/svg/close.svg';
import createIcon from '../../assets/icons/svg/create.svg';
import createPolicyIcon from '../../assets/icons/svg/createPolicy.svg';
import deleteIcon from '../../assets/icons/svg/delete.svg';
import editIcon from '../../assets/icons/svg/edit.svg';
import forbiddenIcon from '../../assets/icons/svg/forbidden.svg';
import groupIcon from '../../assets/icons/svg/group.svg';
import helpIcon from '../../assets/icons/svg/help.svg';
import importIcon from '../../assets/icons/svg/import.svg';
import inboxIcon from '../../assets/icons/svg/inbox.svg';
import infoIcon from '../../assets/icons/svg/info.svg';
import listIcon from '../../assets/icons/svg/list.svg';
import localIcon from '../../assets/icons/svg/local.svg';
import nextIcon from '../../assets/icons/svg/next.svg';
import personIcon from '../../assets/icons/svg/person.svg';
import resourceIcon from '../../assets/icons/svg/resource.svg';
import searchIcon from '../../assets/icons/svg/search.svg';
import statusIcon from '../../assets/icons/svg/status.svg';
import successIcon from '../../assets/icons/svg/success.svg';
import successWhiteIcon from '../../assets/icons/svg/successWhite.svg';
import triangleIcon from '../../assets/icons/svg/triangle.svg';
import urlIcon from '../../assets/icons/svg/url.svg';
import workstationIcon from '../../assets/icons/svg/workstation.svg';
export type IconName =
  | 'checking'
  | 'close'
  | 'create'
  | 'createPolicy'
  | 'delete'
  | 'edit'
  | 'forbidden'
  | 'group'
  | 'help'
  | 'import'
  | 'inbox'
  | 'info'
  | 'list'
  | 'local'
  | 'next'
  | 'person'
  | 'resource'
  | 'search'
  | 'status'
  | 'success'
  | 'successWhite'
  | 'triangle'
  | 'url'
  | 'workstation';
export const COMMON_TM_ICONS = {
  checking: checkingIcon,
  close: closeIcon,
  create: createIcon,
  createPolicy: createPolicyIcon,
  delete: deleteIcon,
  edit: editIcon,
  forbidden: forbiddenIcon,
  group: groupIcon,
  help: helpIcon,
  import: importIcon,
  inbox: inboxIcon,
  info: infoIcon,
  list: listIcon,
  local: localIcon,
  next: nextIcon,
  person: personIcon,
  resource: resourceIcon,
  search: searchIcon,
  status: statusIcon,
  success: successIcon,
  successWhite: successWhiteIcon,
  triangle: triangleIcon,
  url: urlIcon,
  workstation: workstationIcon,
};
