<tm-search-select
  *ngIf="!loading"
  iwFormRowContent
  [control]="formControl"
  [modalComponents]="components"
  [searchParams]="params"
  modalHeader="{{modaltitle}}"
  [additionalTypesToSelect]="additionalItems | async"
></tm-search-select>
<tm-spinner *ngIf="loading"></tm-spinner>
