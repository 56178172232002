import Scopes = TmApi.search.Scopes;

export function getIdStringByScopeString(scope: Scopes): string {
  switch (scope) {
    case 'status':
      return 'IDENTITY_STATUS_ID';
    case 'resource':
      return 'LIST_ID';
    case 'text_object':
      return 'TEXT_OBJECT_ID';
    case 'form':
    case 'stamp':
    case 'table':
      return 'FINGERPRINT_ID';
    case 'image_classifier':
    case 'autoling':
      return 'CATEGORY_ID';
    default:
      return scope.toUpperCase() + '_ID';
  }
}

export function getNameStringByScopeString(scope: Scopes): string {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (scope) {
    case 'application': {
      return 'APPLICATION_ID';
    }
    default:
      return 'DISPLAY_NAME';
  }
}

export function getValueByScope(item: any, scope: TmApi.search.Scopes): TmApi.scope.ConditionValueObject {
  const id = getIdStringByScopeString(scope);
  const name = getNameStringByScopeString(scope);
  const scopeToSave = mapSearchScopeToSaveScope(scope, item);
  const mappedItem: TmApi.scope.ConditionValueObject = {
    TYPE: scopeToSave,
    DATA: item[id],
    IS_DELETED: item.IS_DELETED,
    NAME: item[name],
  };
  if (item.COLOR) {
    mappedItem.COLOR = item.COLOR;
  }
  if (item[id] === undefined || item[name] === undefined) {
    // eslint-disable-next-line no-console
    console.error(`getIdStringByScopeString or getNameStringByScopeString method need fix for '${scope}' scope`);
  }
  return mappedItem;
}

function mapSearchScopeToSaveScope(scope: string, item: any) {
  if (scope === 'image_classifier') {
    return item.TYPE === 'image_classifier' ? 'graphic' : 'card';
  }
  return scope;
}
