import { Component, Input } from '@angular/core';
import { AbstractTabComponent } from './abstract-tab-component';
import { TabComponent } from '../search-select.module';

@Component({
  selector: 'tm-search-tab-host',
  template: ` <ng-container *ngIf="component">
    <ng-container *ngxComponentOutlet="component"></ng-container>
  </ng-container>`,
})
export class SearchTabHostComponent extends AbstractTabComponent {
  @Input() component?: TabComponent;
}
