import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DoBootstrap, Injector, NgModule, NgZone, forwardRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TmCustomElementsService } from '@tm-shared/custom-elements';
import { TmDescriptionElementsModule } from '@tm-shared/description/description-elements.module';
import { TmLazyBlocksElementsModule } from '@tm-shared/lazy-blocks/lazy-blocks-elements.module';
import { TmPolicyElementsModule } from '@tm-shared/policy/policy-elements.module';
import { TmSharedElementsModule } from '@tm-shared/tm-shared-elements.module';
import { TmJqueryAndElementsXsrfInterceptor } from '@tm-shared/xsrf-interceptor';
import { Settings as LuxonSettings } from 'luxon';
import { TmAnalysisTextElementsModule } from 'plugins/analysis-text/analysis-text-elements.module';
import { TmEventsElementsModule } from 'plugins/events/events-elements.module';
import { TmPluginsElementsModule } from 'plugins/plugin/plugin-elements.module';
import { TmElementsModule } from 'plugins/tm/tm-elements.module';
import { TmCommonErrorsAjaxInterceptor } from 'plugins/tm/tm-errors-ajax.interceptor';
import { TmCommonErrorsInterceptor } from 'plugins/tm/tm-errors.interceptor';
import { TmConfigModule } from './plugins/config/config.module';
import {
  IwSharedModule,
  IwIconService,
  IwNotificationsService,
  services as platformProviders,
  IwModalService,
} from '@platform/shared';
import './styles/main.scss';
import './styles/tmbb-hacks.scss';
import { TmOptionsService } from '@tm-shared/options';
import { TmTechLoaderElementsModule } from '@tm-shared/tech-loader/tech-loader-elements.module';
import { TmHelpersService } from '@tm-shared/helpers/helpers.service';
import { TmSearchSelectElementsModule } from './@tm-shared/search-select/search-select-elements.module';
import { RouterModule } from '@angular/router';
import { TmService } from './plugins/tm/tm.service';
import { TmSessionService } from './@tm-shared/session';

const plugins = [TmTechLoaderElementsModule];

/**
 * These should provide and register custom elements by themselves.
 */
const COMPONENT_MODULES = [
  TmConfigModule,
  TmElementsModule,
  TmPluginsElementsModule,
  TmAnalysisTextElementsModule,
  TmSharedElementsModule,
  TmEventsElementsModule,
  TmLazyBlocksElementsModule,
  TmPolicyElementsModule,
  TmDescriptionElementsModule,
  TmSearchSelectElementsModule,
];

/**
 * WARNING: Do not use this module inside angular!
 * It's an entry point for external usage.
 */
@NgModule({
  imports: [
    TmSharedElementsModule.forRoot(),
    IwSharedModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    ...COMPONENT_MODULES,
    ...plugins,
  ],
  providers: [
    TmCommonErrorsAjaxInterceptor,
    { provide: 'TmJqueryXsrfInterceptor', useValue: new TmJqueryAndElementsXsrfInterceptor() },
    { provide: 'TmCommonErrorsAjaxInterceptor', useExisting: forwardRef(() => TmCommonErrorsAjaxInterceptor) },
    { provide: HTTP_INTERCEPTORS, useExisting: 'TmJqueryXsrfInterceptor', multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TmCommonErrorsInterceptor, multi: true },
    { provide: 'TmCustomElementsService', useClass: TmCustomElementsService },
    { provide: 'OPTIONS', useValue: { iconsPath: 'tme-assets/images/' } },
    { provide: 'NgZone', useExisting: NgZone },
    { provide: 'TmTranslateService', useExisting: TranslateService },
    TmService,
    TmHelpersService,
    { provide: 'TmHelpersService', useExisting: TmHelpersService },
    { provide: 'TmSessionService', useExisting: TmSessionService },
    /**
     * Below are @platform/shared goods
     */
    ...platformProviders,
    { provide: 'IwModal', useExisting: IwModalService },
    { provide: 'IwIconService', useExisting: IwIconService },
    { provide: 'IwNotifications', useExisting: IwNotificationsService },
    { provide: 'TM_BASE_URL', useValue: window.location.host },
  ],
})
class TmCustomElementsModule implements DoBootstrap {
  constructor(
    private _translate: TranslateService,
    _injector: Injector,
    private readonly _options: TmOptionsService,
    _elements: TmCustomElementsService
  ) {
    this._options.set(TM_CONFIG);

    const languageFromCookie = document.cookie.match(/language\=(\w{2})/);

    const availableLangs: string[] = ['ru', 'en'];
    const defaultLang: string = languageFromCookie ? languageFromCookie[1] : 'ru';

    this._translate.addLangs(availableLangs);

    this._translate.setDefaultLang(defaultLang);
    this._translate.use(defaultLang);

    LuxonSettings.defaultLocale = defaultLang;
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => (LuxonSettings.defaultLocale = event.lang));

    /**
     * Publish injector to access it from TMBB
     */
    Object.defineProperty(window, 'getTmngService', {
      value: (key: string) => {
        try {
          return _injector.get(key);
        } catch (e) {
          return null;
        }
      },
    });
  }

  public ngDoBootstrap() {}
}

platformBrowserDynamic().bootstrapModule(TmCustomElementsModule);
