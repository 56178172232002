<tm-spinner *ngIf="loading"></tm-spinner>
<input
  type="text"
  *ngIf="!loading"
  [tmGridFilter]="grid"
  propertyToFilter="query"
  [startsWithAny]="false"
  [endsWithAny]="false"
  class="tm-input search-input"
  placeholder="{{ '@tm-shared.search-select.search_placeholder' | translate }}"
  data-qa="search-tab-input"
/>
<tm-grid
  [hidden]="loading"
  class="grid"
  #grid
  [gridOptions]="options"
  [initialRequest]="false"
  [service]="service"
  [storeKey]="null"
  [tableConfig]="config"
>
</tm-grid>
