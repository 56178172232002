import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  GuardsCheckEnd,
  GuardsCheckStart,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { empty } from 'rxjs';
import { bufferCount, filter, groupBy, mergeMap, take } from 'rxjs/operators';

export const RELOAD_URL = '/reload';

@Injectable()
export class TmLegacySupportService implements CanActivate, CanActivateChild {
  constructor(router: Router) {
    this._listenRouterEvents(router);
  }

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const URL = state.url;

    if (this._isTmngUrl(URL)) {
      return true;
    }

    location.assign(URL);

    // Do not respond, we should let page reload
    return empty();
  }

  public canActivateChild() {
    return this.canActivate.apply(this, arguments);
  }

  private _listenRouterEvents(router: Router): void {
    router.events
      .pipe(
        filter((event) => event instanceof GuardsCheckStart || event instanceof GuardsCheckEnd),
        groupBy((event: GuardsCheckStart | GuardsCheckEnd) => event.id),
        mergeMap((group$) => group$.pipe(bufferCount(2), take(1)))
      )
      .subscribe(([eventStart, eventEnd]: [GuardsCheckStart, GuardsCheckEnd]) => {
        // Prevent rejecting user to empty page, listen to guard rejection events
        if (!eventEnd.shouldActivate) {
          // If url is the same most likely user is redirected to root
          let nextUrl = eventStart.url === eventEnd.url && eventStart.url !== RELOAD_URL ? '/' : eventStart.url;

          // If it's not tmng - kick to backbone
          if (!this._isTmngUrl(nextUrl)) {
            location.assign(nextUrl);
          }
        }
      });
  }

  private _isTmngUrl(url: string): boolean {
    return !!TMNG_ROUTES.find((supportedUrl) => new RegExp(supportedUrl).test(url)) || url === RELOAD_URL;
  }
}
