import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError, timer } from 'rxjs';
import { catchError, map, mapTo, retryWhen, switchMap, tap } from 'rxjs/operators';
import { getIdStringByScopeString } from './functions';

@Injectable()
export class TmSearchService {
  public src = '/api/search';

  /**
   * items for inter-component communication
   */
  private _resolvedItems: TmApi.search.CollectionItem = {};

  constructor(private _http: HttpClient) {}

  /**
   * @example
   * options:
   *
   * {
   *  params:{
   *    type: 'query'  - enables search in history tables
   *    scopes: 'tag,person'
   *    'filter[DISPLAY_NAME]':'hello'
   *    'with[joinTable]':'myTable'
   *    'sort[DISPLAY_NAME]': 'asc'
   *    limit: 10
   *    start: 0
   *  }
   * }
   */
  public get(options: TmApi.GetOptions): Observable<TmApi.GetResponse<TmApi.search.CollectionItem>> {
    return this._http.get<TmApi.GetResponse<TmApi.search.CollectionItem>>(this.src.toString(), options).pipe(
      tap((response) => {
        this._resolveResponseItems(response.data);
      })
    );
  }

  public getWithRetries(
    retryDelayMs: number = 50,
    retryLimit: number = 10,
    options: TmApi.GetOptions
  ): Observable<TmApi.GetResponse<TmApi.search.CollectionItem>> {
    return this.get(options).pipe(
      retryWhen((errors$) => {
        return errors$.pipe(
          switchMap((err) => {
            return retryDelayMs > -1 && --retryLimit > 0 ? timer(retryDelayMs) : throwError(err);
          })
        );
      })
    );
  }

  public getItemsByScopeAndId(items: TmApi.search.ScopesWithIds) {
    const resultItem: TmApi.search.CollectionItem = {};

    const scopes = Object.keys(items).filter(
      (scope: keyof TmApi.search.ScopesWithIds) => items[scope] && items[scope]!.length
    ) as (keyof TmApi.search.ScopesWithIds)[];
    let requests: Observable<any>;
    if (scopes.length === 0) {
      requests = of(null);
    } else {
      requests = forkJoin(
        ...scopes.map((scope) => {
          return forkJoin(
            ...items[scope]!.map((id: string | number) => this._getByScopeAndId(id, scope.toString()))
          ).pipe(
            tap((data) => {
              resultItem[scope] = data.filter((item: any) => item);
            })
          );
        })
      );
    }
    return requests.pipe(mapTo(resultItem));
  }

  private _getByScopeAndId(id: string | number, scope: TmApi.search.Scopes): Observable<any> {
    const idName = getIdStringByScopeString(scope);
    if (!this._resolvedItems[scope]) {
      this._resolvedItems[scope] = [];
    }
    const itemFromCache = (this._resolvedItems[scope] as any[]).find((item: any) => item[idName] === id);
    const params: TmApi.GetOptions['params'] = {
      type: 'query',
      scopes: scope,
    };
    params['filter[' + idName + ']'] = id.toString();
    return itemFromCache
      ? of(itemFromCache)
      : this.get({ params }).pipe(
          map((response) => {
            return response.data[scope];
          }),
          map((data) => {
            return data && data.length ? data[0] : null;
          }),
          catchError(() => of(null))
        );
  }

  private _resolveResponseItems(data: TmApi.search.CollectionItem) {
    Object.keys(data).forEach((scope: TmApi.search.Scopes) => {
      data[scope]!.forEach((item: any) => {
        const itemId = getIdStringByScopeString(scope);
        this._replaceItemInResolvedItems(itemId, item, scope);
      });
    });
  }

  private _replaceItemInResolvedItems(itemId: string, newItem: any, scope: TmApi.search.Scopes) {
    if (!this._resolvedItems[scope]) {
      this._resolvedItems[scope] = [];
    }
    this._resolvedItems[scope] = (this._resolvedItems[scope] as any[]).filter(
      (item: any) => item[itemId] !== newItem[itemId]
    );
    this._resolvedItems[scope]!.push(newItem);
  }
}
