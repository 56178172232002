import { Component } from '@angular/core';
import { TmElement } from '../../custom-elements';
import { PersonTabComponent } from '../tab-components/person-tab.component';
import { GroupTabComponent } from '../tab-components/group-tab.component';
import { StatusTabComponent } from '../tab-components/status-tab.component';
import { AdditionalItem, SearchParams } from '../tab-components/common';
import { TmBookwormContactTypeService } from '../../bookworm';
import { TmeAbstractQueryComponent } from './tm-query-base.component';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@TmElement('tme-query-senders')
@Component({
  templateUrl: 'wrapper.html',
  styleUrls: ['wrapper-style.scss'],
})
export class TmeQuerySendersComponent extends TmeAbstractQueryComponent {
  public additionalItems = this.contacts.getContactTypesFor(['person', 'group'], 'sid').pipe(
    map((items) =>
      items
        .map((item) => {
          return <AdditionalItem>{
            mnemo: item.mnemo,
            name: item.name,
            masked: true,
          };
        })
        .concat(<AdditionalItem>{
          mnemo: 'meta',
          name: this._t.instant('@tm-shared.meta.query.description'),
        })
    )
  );

  constructor(private contacts: TmBookwormContactTypeService, private _t: TranslateService) {
    super();
  }

  public components = [PersonTabComponent, GroupTabComponent, StatusTabComponent];
  public params: SearchParams = {
    queryParams: {
      type: 'query',
      scopes: 'person,group,status',
    },
  };
}
