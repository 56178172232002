import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmSharedModule } from '@tm-shared/tm-shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { en, ru } from '../i18n';
import { TmPaginationModule } from '../pagination';
import { TmToolbarModule } from '../toolbar';
import { BooleanCellComponent, CheckboxCellComponent, ColorCellComponent } from './cell-renderers';
import { RoutingCellComponent } from './cell-renderers/html-cell/routing-cell.component';
import { GridFilterDirective } from './grid-filter.directive';
import { TmGridComponent } from './tm-grid.component';
import { GridSelectFilterDirective } from './grid-select-filter.directive';

/**
 * TODO: Write tests for grid
 */
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AgGridModule.withComponents([BooleanCellComponent, CheckboxCellComponent, RoutingCellComponent]),
    TmToolbarModule,
    TmPaginationModule,
    IwSharedModule,
    CommonModule,
    TranslateModule,
    TmSharedModule,
    RouterModule,
  ],
  declarations: [
    TmGridComponent,
    GridFilterDirective,
    GridSelectFilterDirective,
    CheckboxCellComponent,
    BooleanCellComponent,
    RoutingCellComponent,
    ColorCellComponent,
  ],
  exports: [
    TmGridComponent,
    GridFilterDirective,
    GridSelectFilterDirective,
    CheckboxCellComponent,
    BooleanCellComponent,
    RoutingCellComponent,
    ColorCellComponent,
  ],
})
export class TmGridModule {
  constructor(private _t: TranslateService) {
    this._t.setTranslation('ru', ru, true);
    this._t.setTranslation('en', en, true);
  }
}
