import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, throwError, timer, Subject, merge } from 'rxjs';
import { catchError, filter, map, retryWhen, switchMap, tap } from 'rxjs/operators';

const DEFAULT_RETRY_LIMIT = 10;

/**
 * Basic class for api collections (users, roles etc)
 */
@Injectable()
export abstract class TmCollectionLoader<FullItem, CommonItemProperties = Partial<FullItem>> {
  public abstract src: string;

  public readonly idAttribute: string;

  /**
   * items for inter-component communication. Not real cache
   */
  protected _resolvedItems = new Map<number | string, FullItem>();
  private resolvedItemsChange = new Subject<string | number>();

  constructor(protected _http: HttpClient) {}

  /**
   * get item from cache or request from backend if cache doesn't contain element.
   * requires idAttribute to be set
   */
  public getById(id: number | string, ignoreCache?: true): Observable<FullItem> {
    let itemFromCache: FullItem | undefined;
    if (this.idAttribute && !ignoreCache) {
      itemFromCache = this._resolvedItems.get(id);
    }

    return itemFromCache
      ? of(itemFromCache)
      : this._http.get<TmApi.GetResponse<FullItem>>(this.src + '/' + id).pipe(
          map((response) => this.deserialize(response) as TmApi.GetResponse<FullItem>),
          map((response) => response.data),
          tap((response) => this._addToCacheItems([response]))
        );
  }

  public getByIdAndListenForChanges(id: number | string) {
    return merge(
      this.getById(id),
      this.resolvedItemsChange.pipe(
        filter((changedId) => changedId === id),
        switchMap(() => this.getById(id))
      )
    );
  }

  /**
   * get items from cache or server
   */
  public getItemsByIds(itemsIds: (number | string)[]): Observable<FullItem[]> {
    return forkJoin(itemsIds.map((id) => this.getById(id).pipe(catchError(() => of(null))))).pipe(
      map((items) => items.flatMap((f) => (!!f ? [f] : [])))
    );
  }

  /**
   * send update request, update cache items on success
   */
  public updateById(id: number | string, body: CommonItemProperties): Observable<TmApi.GetResponse<FullItem>> {
    return this._http.put<TmApi.GetResponse<FullItem>>(this.src.toString() + '/' + id, this.serialize(body)).pipe(
      map((response) => this.deserialize(response) as TmApi.GetResponse<FullItem>),
      tap((response) => {
        this._addToCacheItems([response.data]);
      })
    );
  }

  /**
   * send delete request, maintain 'cache'
   */
  public remove(id: number | string): Observable<TmApi.ErrorResponse> {
    const url = `${this.src.toString()}/${id}`;
    return this._http.delete(url).pipe(
      tap(() => {
        if (this.idAttribute) {
          this._resolvedItems.delete(id);
        }
      })
    );
  }

  public get(options?: TmApi.GetOptions): Observable<TmApi.GetArrayResponse<FullItem>> {
    return this._http
      .get<TmApi.GetArrayResponse<FullItem>>(this.src.toString(), options)
      .pipe(tap((response) => this._addToCacheItems(response.data)));
  }

  public getWithRetries(
    retryDelayMs: number = 1000,
    retryLimit: number = DEFAULT_RETRY_LIMIT,
    options?: TmApi.GetOptions
  ): Observable<TmApi.GetArrayResponse<FullItem>> {
    return this.get(options).pipe(
      retryWhen((errors$) => {
        return errors$.pipe(
          switchMap((err) => {
            if (err.status === 401) {
              return throwError(err);
            }
            return retryDelayMs > -1 && --retryLimit > 0 ? timer(retryDelayMs) : throwError(err);
          })
        );
      })
    );
  }

  public create(body: CommonItemProperties): Observable<TmApi.GetResponse<FullItem>>;
  public create<T>(body: any): Observable<TmApi.GetResponse<T>>;
  public create(body: CommonItemProperties) {
    return this._http.post<TmApi.GetResponse<FullItem>>(this.src.toString(), this.serialize(body)).pipe(
      map((response) => this.deserialize(response)),
      tap((response) => {
        this._addToCacheItems([response.data]);
      })
    );
  }

  /**
   * if service's idAttribute exists in object -> update, else create
   */
  public createOrUpdate(body: Partial<FullItem>): Observable<TmApi.GetResponse<FullItem>> {
    return (body as any)[this.idAttribute]
      ? this.updateById((body as any)[this.idAttribute], body as CommonItemProperties)
      : this.create(body as CommonItemProperties);
  }

  public patch(
    id: number | string,
    body: CommonItemProperties,
    options?: TmApi.GetOptions
  ): Observable<TmApi.GetArrayResponse<FullItem>> {
    return this._http
      .patch<TmApi.GetArrayResponse<FullItem>>(`${this.src.toString()}/${id}`, this.serialize(body), options)
      .pipe(
        map((response) => this.deserialize(response) as TmApi.GetArrayResponse<FullItem>),
        tap((response) => {
          this._addToCacheItems([response.data]);
        })
      );
  }

  protected deserialize(
    responseData: TmApi.GetResponse<any> | TmApi.GetArrayResponse<any>
  ): TmApi.GetResponse<FullItem> | TmApi.GetArrayResponse<FullItem> {
    return responseData;
  }
  protected serialize(request: CommonItemProperties | FullItem): any {
    return request;
  }

  private _addToCacheItems(responseItems: any[]): void {
    if (this.idAttribute) {
      responseItems.forEach((item) => {
        if (this._resolvedItems.has(item[this.idAttribute])) {
          this._resolvedItems.set(item[this.idAttribute], item);
          this.resolvedItemsChange.next(item[this.idAttribute]);
        } else {
          this._resolvedItems.set(item[this.idAttribute], item);
        }
      });
    }
  }
}
