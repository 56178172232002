import { Component, Input, OnInit } from '@angular/core';
import { TmElement } from '../../custom-elements';
import { SearchParams } from '../tab-components/common';
import { TmeAbstractQueryComponent } from './tm-query-base.component';
import { TextObjectTabComponent } from '../tab-components/text-object-tab.component';
import { FingerprintTabComponent } from '../tab-components/fingerprint-tab.component';
import { FormsTabComponent } from '../tab-components/forms-tab.component';
import { StampTabComponent } from '../tab-components/stamp-tab.component';
import { UnloadTableTabComponent } from '../tab-components/unload-table-tab.component';
import { CategoryTabComponent } from '../tab-components/category-tab.component';
import { ImageClassifierTabComponent } from '../tab-components/image-classifier-tab.component';
import { AutolingTabComponent } from '../tab-components/autoling-tab.component';
import { TmLicenseService, TmTechKey } from '../../license';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TabComponent } from '../search-select.module';
import { map, switchMap, tap } from 'rxjs/operators';
import { SearchSelectService } from '../search-select.service';
import { defer, merge, Observable, of } from 'rxjs';
import { TmSearchService } from '../../api-services';
import { FormControl } from '@angular/forms';
import { TmTextObjectAsyncValidator } from '../../../plugins/analysis-text/text-object-async-validator';
import { IwPopoverOptions } from '@platform/shared';

const COMPONENTS_MAP = {
  category: CategoryTabComponent,
  text_object: TextObjectTabComponent,
  fingerprint: FingerprintTabComponent,
  form: FormsTabComponent,
  stamp: StampTabComponent,
  table: UnloadTableTabComponent,
  image_classifier: ImageClassifierTabComponent,
  autoling: AutolingTabComponent,
} as const;

@UntilDestroy()
@TmElement('tme-tech')
@Component({
  templateUrl: 'tme-tech.component.html',
  styleUrls: ['query.scss', 'wrapper-style.scss', 'tme-tech.component.scss'],
  providers: [
    TmTextObjectAsyncValidator,
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        closeOnMousemoveOutside: true,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
})
export class TmeTechComponent extends TmeAbstractQueryComponent implements OnInit {
  loading = true;
  public components: TabComponent[] = [];
  public params: SearchParams = {
    queryParams: {
      type: 'query',
      scopes: 'category,fingerprint,form,text_object,stamp,table,autoling,image_classifier',
      'filter[category][type]': 'term',
    },
  };

  @Input() public textObjectControl = new FormControl('', { updateOn: 'blur' });

  public textObjectField: Observable<{ MASK: string | undefined; NOTE: string | undefined } | undefined>;

  constructor(
    private licenseService: TmLicenseService,
    private selectService: SearchSelectService,
    private searchService: TmSearchService,
    private textObjectValidation: TmTextObjectAsyncValidator
  ) {
    super();
  }

  public ngOnInit() {
    this.licenseService
      .getTechFeaturesExistingInAnyLicense(Object.keys(COMPONENTS_MAP) as TmTechKey[])
      .pipe(untilDestroyed(this))
      .subscribe((features) => {
        this.params.queryParams.scopes = features.join(',');
        this.params = { ...this.params };
        this.components = features.map((feature) => COMPONENTS_MAP[feature as keyof typeof COMPONENTS_MAP]);
        this.loading = false;
      });
    this.listenToFieldChanges();
  }

  private listenToFieldChanges() {
    this.textObjectControl.valueChanges.pipe(untilDestroyed(this)).subscribe((textObjectValue) => {
      const techValue = this.selectService.unMapFromIwSelect(this.formControl.value);
      if (!techValue.length) {
        return;
      }
      techValue[0].TEXT = textObjectValue || undefined;
      const mapped = this.selectService.mapToIwSelect(techValue);
      this.formControl.patchValue(mapped, { emitEvent: false });
    });

    const pureFormControlChanges = this.formControl.valueChanges.pipe(
      tap((val) => {
        const newItems = this.selectService.unMapFromIwSelect(val).map((item) => {
          const newItem = { ...item };
          delete newItem.TEXT;
          return newItem;
        });
        this.formControl.patchValue(this.selectService.mapToIwSelect(newItems), { emitEvent: false });
      })
    );

    this.textObjectField = merge(
      pureFormControlChanges,
      defer(() => of(this.formControl.value))
    ).pipe(
      switchMap((value) => {
        const val = this.selectService.unMapFromIwSelect(value);
        if (val.length === 1 && !val[0].IS_DELETED && val[0].TYPE === 'text_object') {
          this.textObjectControl.patchValue(val[0].TEXT || '', { emitEvent: false });
          this.textObjectControl.setAsyncValidators(this.textObjectValidation.getAsyncObjectValidation(val[0].DATA));
          this.textObjectControl.updateValueAndValidity();

          return this.searchService
            .getItemsByScopeAndId({
              text_object: [val[0].DATA],
            })
            .pipe(
              map((response) => ({
                MASK: response.text_object?.[0]?.MASK,
                NOTE: response.text_object?.[0]?.NOTE,
              }))
            );
        } else {
          this.textObjectControl.patchValue('');
          return of(undefined);
        }
      })
    );
  }
}
