import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TmBookwormFormatsService } from '../../bookworm';
import { IwBytesWithUnitPipe } from '@platform/shared';
import { FingerprintTabComponent } from './fingerprint-tab.component';

@Component({
  templateUrl: './fingerprint-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsTabComponent extends FingerprintTabComponent {
  public static scope = 'form' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.forms';

  public scopeToEmitOnChange = FormsTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: FormsTabComponent.scope,
  };

  constructor(
    service: TmSearchService,
    t: TranslateService,
    formatsService: TmBookwormFormatsService,
    bytesWithUnitPipe: IwBytesWithUnitPipe,
    cd: ChangeDetectorRef
  ) {
    super(service, t, formatsService, bytesWithUnitPipe, cd);
  }
}
