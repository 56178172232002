import { Injectable, OnDestroy } from '@angular/core';
import { TmGridState } from '@tm-shared/grid/tm-grid-state';
import { UrlStreams } from '@tm-shared/url-streams';
import { GridApi } from 'ag-grid-community';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  forkJoin,
  merge,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  finalize,
  map,
  mapTo,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { LoadingTracker } from '../dataloader/loading-tracker';
import { GridInternalSelectionsService } from './grid-internal-selections.service';

/**
 * internal grid logic. Api requests.
 */
@Injectable()
export class GridInternalLogicService<Model> implements OnDestroy {
  public set apiService(api: TmGrid.grid.ApiService) {
    this._apiService = api;
    this._setSrcUpdater();
  }

  public get apiService() {
    return this._apiService;
  }

  public get src() {
    return this._src;
  }
  public readonly retryReqDelay: number = 2000;

  public readonly retryReqTimes: number = 10;

  public dataStable$: ReplaySubject<TmGrid.grid.DataWithMeta> = new ReplaySubject(1);
  public dataStableTotalLength$ = this.dataStable$.pipe(
    map((response) => Math.ceil((response.meta || {}).totalCount || response.data.length))
  );

  public error$: Observable<boolean>;

  public pagesTotal$ = combineLatest([this.dataStableTotalLength$, this._state.pageLimitStable$]).pipe(
    map(([length, limit]) => Math.ceil(length / limit))
  );

  public filter$ = this._state.filter$;

  public minimumLimitOrTotal$ = combineLatest([this.dataStableTotalLength$, this._state.pageLimitStable$]).pipe(
    map(([total, limit]) => (total < limit ? total : limit))
  );

  public loading$: Observable<boolean>;

  public empty$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public gridApi$: Observable<GridApi>;

  private _errors$: ReplaySubject<boolean> = new ReplaySubject(1);

  private _apiService: TmGrid.grid.ApiService;

  private _src: UrlStreams = UrlStreams.create('');

  private _destroyed$ = new Subject();

  private _fetchSubject$ = new Subject();

  /**
   * Contain loading models ids
   */
  private _loadingModelIds$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  private loadingService = new LoadingTracker();

  constructor(private _selectionsService: GridInternalSelectionsService<Model>, private _state: TmGridState) {
    this._fetchSubject$
      .pipe(
        switchMap(() => this._fetch()),
        takeUntil(this._destroyed$)
      )
      .subscribe();

    this.error$ = merge(this._errors$.pipe(mapTo(true)), this.dataStable$.pipe(mapTo(false))).pipe(shareReplay(1));

    this.loading$ = this.loadingService.loading;
  }

  public isModelLoading(id: string): boolean {
    return Boolean(this._loadingModelIds$.getValue().find((modelId) => modelId === id));
  }

  public setTableParams(params: TmGrid.grid.TableConfigParams) {
    if (params) {
      this.src.setParams(params);
    }
  }

  public deleteAllSelected(options?: TmApi.GetOptions) {
    const newAllSelected = this._selectionsService.getAllSelectedIds();
    return this._deleteItemsByIds(newAllSelected, options);
  }

  public deleteByFn(filterFn: (item: Model) => boolean, options?: TmApi.GetOptions) {
    const idAttr = this._apiService.idAttribute;
    if (!idAttr) {
      return throwError(null);
    }
    const newAllSelected = this._selectionsService.getAllSelected();
    const filtered = newAllSelected.filter(filterFn).map((item: any) => item[idAttr]);
    return this._deleteItemsByIds(filtered, options);
  }

  public fetch(): void {
    this._fetchSubject$.next();
  }

  public remove(id: string | number, options?: TmApi.GetOptions, refetch = true) {
    if (!this.apiService.remove) {
      return throwError(null);
    }

    return this.apiService.remove(id, options).pipe(
      tap(() => {
        if (refetch) {
          this.fetch();
        }
      })
    );
  }

  public selectById(ids: number | string | (number | string)[]) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    this._selectionsService.resetAllSelected(ids);

    this.gridApi$.pipe(takeUntil(this._destroyed$), take(1)).subscribe((api: GridApi) => {
      (ids as (number | string)[])
        .map((id) => api.getRowNode(typeof id === 'number' ? `${id}` : id))
        .filter((node) => node)
        .forEach((node) => api.selectNode(node as NonNullable<typeof node>, true));
    });
  }

  public restoreState(storeId: string, storeKey: TmGrid.grid.StoreKey): void {
    this._state.storeId = storeId;
    this._state.storeKey = storeKey;
    this._state.restore();
  }

  public selectPage(page: number): void {
    this._state.setPage(page);
  }

  public selectLimit(limit: number): void {
    this._state.setPageLimit(limit);
  }

  public updateFilter(key: string | number | symbol, value: string | number | null | string[]) {
    const state = this._state.getFilterStateFor(key.toString());
    if (state !== value) {
      this._state.setFilter(key.toString(), value);
      this.selectPage(0);
    }
  }

  public sortBy(sortModel: TmGrid.grid.SortModel[]): void {
    this._state.setSort(sortModel);
  }

  public updateById(id: any, data: Model) {
    return this.wrapWithModelLoadingState(this.apiService.updateById!(id, data), id).pipe(tap(() => this.fetch()));
  }

  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public resetSelection() {
    this.gridApi$.pipe(take(1), takeUntil(this._destroyed$)).subscribe((api) => api.deselectAll());
    this._selectionsService.resetAllSelected([], true);
  }

  /**
   * Задать значение данных вручную
   */
  public setItemsToData(response: TmApi.GetArrayResponse<any>) {
    this.dataStable$.next(this._deserializeWithMeta(response));
  }

  private _deleteItemsByIds(items: (string | number)[], options?: TmApi.GetOptions) {
    const arrayToRemove = items.map((id) =>
      this.loadingService.trackRequest(this.remove(id, options, false)).pipe(
        tap(() => {
          items = items.filter((selectedId) => selectedId !== id);
        }),
        catchError((e) => of(e))
      )
    );

    return forkJoin(arrayToRemove).pipe(
      finalize(() => {
        this.resetSelection();
        if (items.length) {
          this.selectById(items);
        }
        this._selectionsService.resetAllSelected(items, true);
        this.fetch();
      })
    );
  }

  private _fetch(): Observable<any> {
    return this.loadingService.trackRequest(
      this.apiService
        .getWithRetries(this.retryReqDelay, this.retryReqTimes, {
          params: this.src.getParams(),
        })
        .pipe(
          map((response) => {
            if (this.src.getParam('scopes') && response.data) {
              response.data = response.data[this.src.getParam('scopes')];
            }
            return response;
          }),
          tap((data) => {
            this.dataStable$.next(this._deserializeWithMeta(data));
            this.empty$.next(!(data && data.data && data.data.length));
          }),
          take(1),
          takeUntil(this._destroyed$)
        )
    );
  }

  private _setSrcUpdater() {
    combineLatest([this._state.pageLimit$, this._state.start$, this._state.filterMapped$, this._state.sortMapped$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([limit, start, filterObj, sortObj]) => {
        this._setObjectToSrc({ limit, start });
        this._setObjectToSrc(filterObj);
        this._setObjectToSrc(sortObj);
      });
  }

  private _setObjectToSrc(obj: any) {
    if (obj) {
      Object.keys(obj).forEach((current: any) => {
        if (obj[current]) {
          this.src.setParam(current, obj[current]);
        } else {
          this.src.deleteParam(current);
        }
      });
    }
  }

  /**
   * Add loading state to row by it's id
   */
  private wrapWithModelLoadingState(stream$: Observable<any>, id: any) {
    return of(true).pipe(
      tap(() => this._loadingModelIds$.next(this._loadingModelIds$.getValue().concat([id]))),
      switchMapTo(stream$),
      tap(() => this._loadingModelIds$.next(this._loadingModelIds$.getValue().filter((modelId) => modelId !== id))),
      catchError((response) => {
        this._loadingModelIds$.next(this._loadingModelIds$.getValue().filter((modelId) => modelId !== id));
        return of(response);
      })
    );
  }

  private _deserializeMeta(response: TmApi.GetArrayResponse<any>, length?: number): TmShared.collection.Meta {
    const meta = Object.assign({}, response.meta);
    if (!meta.totalCount) {
      meta.totalCount = length;
    }
    return meta;
  }

  private _deserializeWithMeta(response: TmApi.GetArrayResponse<any>) {
    return {
      meta: this._deserializeMeta(response, response.data.length),
      data: response.data,
    };
  }
}
