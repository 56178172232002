import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TmConfigLocalService } from '../services';
import { TmSessionService } from '../../../@tm-shared/session';
import { ConfigDataGet } from '../generated/config';

export interface LocalConfig {
  config: ConfigDataGet;
  isCurrentUser: boolean;
}

@Injectable()
export class TmConfigWidgetService {
  public localConfig$ = new ReplaySubject<LocalConfig>(1);

  private _disconnectWidget$: Subject<void> = new Subject();

  constructor(private _configLocal: TmConfigLocalService, private _session: TmSessionService) {}

  public connect(): void {
    this._session.session$.pipe(take(1), takeUntil(this._disconnectWidget$)).subscribe(() => {
      this._connectWidgetToLocalService();
    });
  }

  public disconnect(): void {
    this._disconnectWidget$.next();
  }

  private _connectWidgetToLocalService(): void {
    this._disconnectWidget$.next();

    this._configLocal.dataWithUpdates$
      .pipe(
        switchMap((config) => {
          return this._session.isCurrentUserById(config.USER_ID).pipe(map((isCurrent) => ({ config, isCurrent })));
        }),
        takeUntil(this._disconnectWidget$)
      )
      .subscribe(({ config, isCurrent }) => {
        this.localConfig$.next({
          config,
          isCurrentUser: isCurrent,
        });
      });
  }
}
