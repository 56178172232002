import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TmElement } from '@tm-shared/custom-elements';
import { DateTime } from 'luxon';
import { Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { LocalConfig, TmConfigWidgetService } from './config-widget.service';
import { OldStatus, Status } from '../generated/config';
import { IwModalService, IwPopoverOptions } from '@platform/shared';
import { Mode, TmConfigModalComponent } from '../config-modal/config-modal.component';
import { getFlagsByConfig } from '../common';

const statusToKey = {
  [Status.FREE]: 'default',
  [Status.LOCKED]: 'locked',
  [Status.SAVED]: 'saved',
  [Status.NEEDS_TRAINING]: 'needs_training',
  [Status.IS_TRAINING]: 'is_training',
};

@TmElement('tme-config-widget')
@Component({
  selector: 'tm-config-widget',
  templateUrl: './config-widget.component.html',
  styleUrls: ['./config-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: IwPopoverOptions,
      useValue: Object.assign(new IwPopoverOptions(), <Partial<IwPopoverOptions>>{
        showDelay: 0,
        hideDelay: 0,
        triggers: 'mouseenter:mouseleave',
      }),
    },
  ],
})
export class TmConfigWidgetComponent implements OnInit, OnDestroy {
  @Input() public localConfig?: LocalConfig;

  @Output() public fetchQueued: EventEmitter<void> = new EventEmitter();

  public showOnPage = false;

  public showButtons: boolean;
  public disableRevert: boolean;
  public disableRevertReason: string;
  public disableApply: boolean;
  public disableApplyReason: string;
  public hideSaveButton: boolean;
  public learningInProgress: boolean;

  public localConfigurationStatusText: string;

  public training: string;
  public lastEdited: string;

  private _destroy$ = new Subject<void>();

  constructor(
    private _t: TranslateService,
    public cd: ChangeDetectorRef,
    private _service: TmConfigWidgetService,
    private modalService: IwModalService
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit() {
    this._listenToService();
    //  TODO: angular-way по выезду из кофе
    timer(0, 50)
      .pipe(
        map(() => this.getShowOnPageFlag()),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe((show) => {
        this.showOnPage = show;
        this.cd.detectChanges();
      });
  }

  private getShowOnPageFlag() {
    return (
      location.href.includes('protected') ||
      location.href.includes('analysis') ||
      location.href.includes('policy') ||
      location.href.includes('lists') ||
      location.href.includes('notification') ||
      location.href.includes('organization')
    );
  }

  public popoverVisibilityChanged(_visible: boolean): void {
    this.cd.detectChanges();
  }

  public openConfigModal(mode: Mode) {
    this.modalService.open(TmConfigModalComponent, { mode });
    this.cd.detectChanges();
  }

  public formatTimestamp(timestamp: number): string {
    return DateTime.fromMillis(timestamp).toFormat('D T');
  }

  private _listenToService() {
    this._service.localConfig$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
      this.localConfig = data;
      if (!this.localConfig) {
        return;
      }

      this._updateConfigStatusText(this.localConfig);
      this._updateNeedsTrainingText(this.localConfig);

      const obj = getFlagsByConfig(this.localConfig.config, this._t, this.localConfig.isCurrentUser);
      this.showButtons = obj.showButtons;
      this.disableRevert = obj.disableRevert;
      this.disableRevertReason = obj.disableRevertReason;
      this.disableApply = obj.disableApply;
      this.disableApplyReason = obj.disableApplyReason;
      this.hideSaveButton = obj.hideSaveButton;
      this.learningInProgress = obj.learningInProgress;

      this.cd.detectChanges();
    });
  }

  private _updateConfigStatusText(localConfig: LocalConfig) {
    const date = DateTime.fromSQL(localConfig.config.CHANGE_DATE!, { zone: 'utc' }).toLocal().toFormat('DD HH:mm');
    this.lastEdited = this._t.instant(`config.widget.title.lastEdited`, { date });

    if (
      localConfig.isCurrentUser &&
      (OldStatus.LOCKED === localConfig.config.OLD_STATUS || Status.LOCKED === localConfig.config.STATUS)
    ) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.editedByActiveUser`);
      return;
    }

    if (
      localConfig.isCurrentUser &&
      (localConfig.config.OLD_STATUS === OldStatus.SAVED || localConfig.config.STATUS === Status.SAVED)
    ) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.savedByActiveUser`);
      return;
    }

    if (!localConfig.isCurrentUser && localConfig.config.OLD_STATUS === OldStatus.SAVED) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.saved`, {
        username: localConfig.config.user?.DISPLAY_NAME,
      });
      return;
    }

    let status = localConfig.config.STATUS;
    if (!localConfig.isCurrentUser && (status === Status.NEEDS_TRAINING || status === Status.IS_TRAINING)) {
      status = Status.LOCKED;
    }

    if (status === Status.IS_TRAINING) {
      this.localConfigurationStatusText = '';
      return;
    }

    // @translate config.widget.title.saved
    // @translate config.widget.title.locked
    // @translate config.widget.title.default
    if (localConfig.config.user) {
      this.localConfigurationStatusText = this._t.instant(`config.widget.title.${statusToKey[status]}`, {
        username: localConfig.config.user?.DISPLAY_NAME,
      });
    }
  }

  private _updateNeedsTrainingText(localConfig: LocalConfig) {
    if (localConfig.config.STATUS === Status.IS_TRAINING) {
      // @translate config.widget.title.image_classifier
      // @translate config.widget.title.autoling
      const tech = this._t.instant(`config.widget.title.${localConfig.config.TECHNOLOGY}`);
      this.training = this._t.instant('config.widget.title.is_training', { tech });
      return;
    }
    this.training = '';
  }
}
