import { Injectable } from '@angular/core';

import { ExportFilesModel, ExportModel } from '../export-monitor/export-monitor.model';
import { TmStatefulService } from '../../../../@tm-shared/dataloader';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class TmExportMonitorService extends TmStatefulService<ExportModel[]> {
  public src = '/api/reportExport';

  public openMenu = new Subject();

  public updateExportStatus(data: ExportModel, openMenu = true): void {
    const currentCache = this.data$.getValue() || [];
    const itemInCache = currentCache.findIndex((item) => item.REPORT_ID === data.REPORT_ID);
    if (itemInCache !== -1) {
      currentCache[itemInCache] = { ...data };
    } else {
      currentCache.push(data);
    }
    this.saveLatestData(currentCache);
    if (openMenu) {
      this.openMenu.next();
    }
  }

  public updateExportFiles(data: ExportFilesModel): void {
    const currentCache = this.data$.getValue() || [];
    const item = currentCache.find((item) => item.REPORT_ID === data.report_id);
    if (item) {
      item.PARAMS = [data];
    }
    this.saveLatestData(currentCache);
  }

  public remove(id: number) {
    return this.http.delete(`${this.src}/${id}`).pipe(tap(() => this.deleteItemFromCache(id)));
  }

  private deleteItemFromCache(id: number) {
    const newCache = this.data$.getValue()?.filter((item) => item.REPORT_ID !== id);
    this.saveLatestData(newCache);
  }

  protected deserialize(response: any): ExportModel[] {
    return response.data;
  }
}
