<div style="position: relative; height: 300px;">
  <tm-tree
    [useCheckbox]="true"
    [useVirtualScroll]="true"
    [treeItemsWithLinks]="false"
    [getChildren]="getChildren"
    [apiService]="ldapGroupService"
  ></tm-tree>
</div>

<div>
  <hr />
  <h3>{{ '@tm-shared.search-select.searchTitles.deletedGroup' | translate }}</h3>
  <tm-grid [height]="100" [service]="searchService" [gridOptions]="gridOptions" [tableConfig]="config"></tm-grid>
</div>
