import { Component, Input, OnInit } from '@angular/core';
import { TmElement } from '../../custom-elements';
import { ADDITIONAL_ITEMS, SearchParams } from '../tab-components/common';
import { TmeAbstractQueryComponent } from './tm-query-base.component';
import { ApplicationTabComponent } from '../tab-components/application-tab.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TmBookwormEventsService } from '../../bookworm';
import { finalize, map, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@UntilDestroy()
@TmElement('tme-applications')
@Component({
  templateUrl: 'wrapper.html',
  styleUrls: ['wrapper-style.scss'],
})
export class TmeApplicationsComponent extends TmeAbstractQueryComponent implements OnInit {
  @Input() filterapps?: string;
  public additionalItems = of([ADDITIONAL_ITEMS.APPLICATION]);
  public components = [ApplicationTabComponent];
  public params: SearchParams = {
    queryParams: {
      scopes: 'application',
    },
  };

  constructor(private events: TmBookwormEventsService) {
    super();
  }

  public ngOnInit() {
    if (!this.filterapps) {
      return;
    }
    this.loading = true;
    this.loadEventFilter(this.events, this.filterapps.split(','))
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe((configUpdate) => {
        this.params = {
          ...this.params,
          ...{
            queryParams: {
              ...this.params.queryParams,
              ...configUpdate,
            },
            tabParams: {
              [ApplicationTabComponent.name]: {
                query: configUpdate,
              },
            },
          },
        };
      });
  }

  private loadEventFilter(events: TmBookwormEventsService, mnemos: string[]) {
    const mnemoRequests = mnemos.map((item) => events.getIdByMnemo(item));
    return combineLatest(mnemoRequests).pipe(
      map((ids) => {
        return {
          'filter[application][OBJECT_TYPE_ID][]': ids as string[],
        };
      }),
      take(1)
    );
  }
}
