import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TmFormElementService } from './form-elements.service';
import { TmInputManyComponent } from './input-many/input-many.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputMaskDirective } from './masked-input/input-mask.directive';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [TmInputManyComponent, InputMaskDirective],
  providers: [TmFormElementService, { provide: 'TmFormElementService', useExisting: TmFormElementService }],
  exports: [InputMaskDirective],
})
export class TmFormElementsModule {}
