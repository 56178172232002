import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { debounceable } from '@platform/shared';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TmGridComponent } from './tm-grid.component';

@Directive({
  selector: '[tmGridFilter]',
})
export class GridFilterDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input('tmGridFilter') public tableToFilter: TmGridComponent<any>;
  /**
   * api model attribute or any special
   */
  @Input() public propertyToFilter: string;

  /**
   * add * to start of searchable value
   */
  @Input() public startsWithAny: boolean;

  /**
   * add * to end of searchable value
   */
  @Input() public endsWithAny = true;

  private _destroy$ = new Subject();
  private _filterSubscription: Subscription;

  constructor(private _el: ElementRef) {}

  public ngAfterViewInit(): void {
    this._updateSubscription();
  }

  public ngOnChanges(changes: SimpleChanges) {
    let propertyToFilter = changes.propertyToFilter;
    if (
      propertyToFilter &&
      !propertyToFilter.firstChange &&
      propertyToFilter.previousValue !== propertyToFilter.currentValue
    ) {
      (<HTMLInputElement>this._el.nativeElement).value = '';
      this.tableToFilter.updateFilter(propertyToFilter.previousValue, '');
      this._filterSubscription.unsubscribe();
      this._updateSubscription(propertyToFilter.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('input', ['$event'])
  @debounceable(300)
  protected _onInput(event: KeyboardEvent) {
    let value = (<HTMLInputElement>event.target).value;
    if (this.startsWithAny) {
      value = '*' + value;
    }
    if (this.endsWithAny) {
      value += '*';
    }
    this.tableToFilter.updateFilterAndRefresh(this.propertyToFilter, value);
  }

  private _restoreInputState(state: string | null) {
    if (state) {
      if (state.startsWith('*')) {
        state = state.substring(1);
      }
      if (state.endsWith('*')) {
        state = state.substring(0, state.length - 1);
      }
      (<HTMLInputElement>this._el.nativeElement).value = state;
    } else {
      (<HTMLInputElement>this._el.nativeElement).value = '';
    }
  }

  private _updateSubscription(filter?: string) {
    if (this.tableToFilter) {
      this._filterSubscription = this.tableToFilter
        .getFilterStateChange(filter || this.propertyToFilter)
        .pipe(takeUntil(this._destroy$))
        .subscribe((state) => this._restoreInputState(state as string));
    } else {
      // для исправления ошибки использовать либо ссылки # в шаблоне, либо корректно настроить viewchild.
      // Так же возможен вариант отрисовки поля раньше, чем грида - тогда синхронизировать отрисовку.
      throw Error('NO GRID TO FILTER');
    }
  }
}
