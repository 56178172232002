import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { TmLdapGroupHierarchyApiService, TmSearchService } from '@tm-shared/api-services';
import { AbstractTabComponent } from './abstract-tab-component';
import { TreeNode } from '@circlon/angular-tree-component';
import { ASYNC_ROOT, TmTreeComponent, TmTreeNodeData } from '../../tree';
import { map, skip, takeUntil } from 'rxjs/operators';
import { TmGridComponent, TmGridOptions } from '../../grid';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { TranslateService } from '@ngx-translate/core';
import { getValueByScope } from '../../api-services/functions';
import ConditionValueObject = TmApi.scope.ConditionValueObject;

@Component({
  templateUrl: 'group-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupTabComponent extends AbstractTabComponent implements AfterViewInit {
  public static scope = 'group' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.group';

  public config: TmGrid.grid.TableConfigParams = {
    scopes: 'group',
    type: 'query',
    'filter[IS_DELETED]': '1',
  };

  public gridOptions: TmGridOptions = {
    columnDefs: [
      {
        width: 40,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapGroup.displayName'),
      },
    ],
  };

  @ViewChild(TmTreeComponent) public tree: TmTreeComponent;
  @ViewChild(TmGridComponent) public grid: TmGridComponent<TmApi.ldapGroup.CollectionItem>;

  private deletedForEmit: ConditionValueObject[] = [];
  private existingForEmit: ConditionValueObject[] = [];

  public ngAfterViewInit() {
    const selected = this.selected.filter((item) => item.TYPE === 'group');
    this.deletedForEmit = selected.filter((item) => item.IS_DELETED);
    this.existingForEmit = selected.filter((item) => !item.IS_DELETED);
    this.tree.selectNodesByIds(this.existingForEmit.map((item) => item.DATA));
    this.grid.selectById(this.deletedForEmit.map((item) => item.DATA));
    this.setListeners(selected);
  }

  private setListeners(startingSelected: ConditionValueObject[]) {
    this.tree.onSelectionChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.existingForEmit = this.getSelectedInTree();
      this.emitChangedSelection();
    });

    this.grid.allSelectedIds$.pipe(skip(1), takeUntil(this.destroy$)).subscribe((currentlySelectedIds) => {
      this.deletedForEmit = currentlySelectedIds.map((id) => {
        const itemFromGrid = this.grid
          .getAllSelected()
          .find((item) => (item ? item[this.grid.idAttribute as keyof typeof item] === id : false));
        const previousSelected = startingSelected.find((item) => item.DATA === id);
        return itemFromGrid
          ? getValueByScope(itemFromGrid, GroupTabComponent.scope)
          : (previousSelected as ConditionValueObject);
      });
      this.emitChangedSelection();
    });
  }

  private emitChangedSelection() {
    const allItems = this.selected.filter((item) => item.TYPE !== 'group');
    allItems.push(...this.deletedForEmit, ...this.existingForEmit);
    this.selectedChange.emit(allItems);
  }

  private getSelectedInTree() {
    let selected = this.tree.getSelectedIds(true, true);
    const rootNode = this.tree.getNodeById(ASYNC_ROOT);
    if (rootNode?.isSelected) {
      selected = rootNode.children.map((item) => item.data.id);
    }
    return selected.map((id) => {
      const data = this.tree.getNodeById(id)?.data;
      return {
        TYPE: GroupTabComponent.scope,
        DATA: data.id,
        NAME: data.name,
      };
    });
  }

  constructor(
    public ldapGroupService: TmLdapGroupHierarchyApiService,
    public searchService: TmSearchService,
    private _t: TranslateService
  ) {
    super();
  }

  /**
   * getChildren method for tm-tree getChildren input
   * sets logic for loading of lazy nodes
   */
  public getChildren = (node: TreeNode): Promise<TmTreeNodeData[]> => {
    return node.realParent
      ? this.ldapGroupService
          .get({
            params: {
              grouppath: node.data.ID_PATH || node.data.ID_NAME_PATH.ID_PATH,
            },
          })
          .pipe(map((response) => this._mapGroupToTreeNode(response.data)))
          .toPromise()
      : this.ldapGroupService
          .get()
          .pipe(map((response) => this._mapGroupToTreeNode(response.data)))
          .toPromise();
  };

  private _mapGroupToTreeNode(items: TmApi.ldapGroup.CollectionItem[]): TmTreeNodeData[] {
    return items.map((item) => {
      return {
        name: item.DISPLAY_NAME,
        hasChildren: !!item.childsCount,
        ID_PATH: item.ID_PATH || item.ID_NAME_PATH.ID_PATH,
        id: item.GROUP_ID,
      };
    });
  }
}
