import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IwModalService } from '@platform/shared';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { SearchSelectService } from './search-select.service';
import { TmSearchTabModalComponent } from './search-tab-modal.component';
import { TabComponent } from './search-select.module';
import { AdditionalItem, Searchable, SearchParams } from './tab-components/common';
import { SearchableSearchService } from './searchable-search.service';

@Component({
  selector: 'tm-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmSearchSelectComponent implements OnInit, OnDestroy {
  @Input() modalComponents: TabComponent[] | null;
  @Input() searchParams: SearchParams | null;

  @Input() public additionalTypesToSelect: AdditionalItem[] | null;
  @Input() public modalHeader: string;
  @Input() public placeholder?: string;
  @Input() public control?: FormControl;

  public typeToShowHelpText = [
    'stamp',
    'form',
    'text_object',
    'graphic',
    'card',
    'autoling',
    'category',
    'fingerprint',
  ];

  private _destroy$ = new Subject();

  private service: Searchable;

  constructor(
    private _modalService: IwModalService,
    public searchSelectService: SearchSelectService,
    private cdr: ChangeDetectorRef,
    private injector: Injector
  ) {}

  public ngOnInit() {
    this.service = this.injector.get(this.searchParams?.api ?? SearchableSearchService);
    this.control?.statusChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this.cdr.markForCheck());
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async openDialog(): Promise<void> {
    if (!this._modalService.activeModal) {
      const modal = await this._modalService.open(TmSearchTabModalComponent, {
        components: this.modalComponents,
        tabParams: this.searchParams?.tabParams,
        selected: this.searchSelectService.unMapFromIwSelect(this.control?.value),
        modalHeader: this.modalHeader,
      });

      modal.component.changed
        .pipe(
          take(1),
          takeUntil(modal.dismissed),
          map(this.searchSelectService.mapToIwSelect),
          takeUntil(this._destroy$)
        )
        .subscribe((data) => this.control?.setValue(data));
    }
  }

  public getSearchCallback(): (options: { query: string }) => Observable<IwSelectItem[]> {
    return ({ query }) => {
      return this.service
        .search({
          ...this.searchParams?.queryParams,
          query,
        })
        .pipe(
          map((conditions) => this.searchSelectService.mapToIwSelect(conditions)),
          map((apiItems) =>
            this.searchSelectService.addAdditionalItems(
              apiItems,
              this.control!.value,
              query,
              this.additionalTypesToSelect || []
            )
          )
        ) as Observable<IwSelectItem[]>;
    };
  }
}
