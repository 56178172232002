import { Injectable } from '@angular/core';
import { SelectionChangedEvent } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GridInternalSelectionsService<Model> {
  /**
   * currently displayed grid grows
   */
  public gridRowsData: Model[];
  public idAttribute: string | number;
  public selected$: Observable<Model[]>;
  public selectedOrdered$: Observable<Model[]>;
  public allSelectedIds$: Observable<(number | string)[]>;
  public allSelected$: Observable<Model[]>;

  private _selected$ = new BehaviorSubject([] as Model[]);
  private _selectedOrdered$ = new BehaviorSubject([] as Model[]);
  private _allSelectedIds$ = new BehaviorSubject([] as (string | number)[]);
  private _allSelected$ = new BehaviorSubject([] as Model[]);

  constructor() {
    this.selected$ = this._selected$.asObservable();
    this.selectedOrdered$ = this._selectedOrdered$.asObservable();
    this.allSelectedIds$ = this._allSelectedIds$.asObservable();
    this.allSelected$ = this._allSelected$.asObservable();
  }

  public getAllSelectedIds() {
    return this._allSelectedIds$.getValue();
  }

  public getAllSelected() {
    return this._allSelected$.getValue();
  }

  public resetAllSelected(items: any[], force = false) {
    if (!this._allSelectedIds$.getValue().length || force) {
      this._allSelectedIds$.next(items);
    }
  }

  public onAgSelectionChange(e: SelectionChangedEvent) {
    const rows = e.api.getSelectedRows() || [];
    this._updateOrderedSelection(rows);
    this._updateAllSelectedElements(rows);
    this._selected$.next(rows);
  }

  /**
   * maintain selection of all elements across pages
   */
  private _updateAllSelectedElements(selection: Model[]) {
    let currentAllSelected = this._allSelectedIds$.getValue();
    const itemsToDelete = currentAllSelected
      .filter((selectedItemId) =>
        this.gridRowsData.find((gridRow: any) => gridRow[this.idAttribute].toString() === selectedItemId.toString())
      ) // only delete elements currently presented in table
      // eslint-disable-next-line
      .filter(
        (selectedItemId) =>
          !selection.find((selectItem: any) => selectItem[this.idAttribute].toString() === selectedItemId.toString())
      );

    // delete items
    currentAllSelected = currentAllSelected.filter((item) => !itemsToDelete.includes(item));

    // check if new items exist and add
    const newItems = selection
      .filter(
        (currentSelectedItem: any) =>
          !currentAllSelected.find(
            (allSelectedItemId) => allSelectedItemId.toString() === currentSelectedItem[this.idAttribute].toString()
          )
      )
      .map((item: any) => item[this.idAttribute]);

    currentAllSelected.push(...newItems);

    const allModels = [...selection, ...this._allSelected$.value];
    const items = currentAllSelected.map((currentId) => {
      return allModels.find((item: any) => item?.[this.idAttribute].toString() === currentId.toString())!;
    });

    this._allSelected$.next(items);
    this._allSelectedIds$.next(currentAllSelected);
  }

  private _updateOrderedSelection(selection: any[]) {
    let currentSelectedItems = this._selectedOrdered$.getValue();
    if (selection.length === 1) {
      currentSelectedItems = selection;
    } else if (selection.length > currentSelectedItems.length) {
      currentSelectedItems.unshift(selection.find((item) => !currentSelectedItems.includes(item)));
    } else if (selection.length < currentSelectedItems.length) {
      currentSelectedItems = currentSelectedItems.filter((item) => selection.includes(item));
    }
    this._selectedOrdered$.next(currentSelectedItems);
  }
}
