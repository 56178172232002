<iw-select
  *ngIf="control"
  class="selectControl"
  placeholder="{{ placeholder || '@tm-shared.search-select.form.search' | translate }}"
  [formControl]="control"
  [multiple]="true"
  [searchable]="true"
  [server]="getSearchCallback()"
  data-qa="scopeSelectInput"
  [labelTemplate]="labelTemplate"
  [optionTemplate]="optionTemplate"
></iw-select>

<ng-template #labelTemplate let-item="item" let-unselectItem="unselectItem">
  <div class="value-wrapper default" [class.disabled]="item.disabled">
    <span class="value-icon">
      <ng-container *ngTemplateOutlet="iconTemplate; context: { type: item.value.TYPE }"></ng-container>
      <ng-container *ngTemplateOutlet="helpTextTemplate; context: { type: item.value.TYPE }"></ng-container>
    </span>
    <span>
      <span [ngClass]="{ 'deleted-item': item.value.IS_DELETED }">{{ item.label }}</span>
    </span>
    <span class="value-icon right" aria-hidden="true" (click)="unselectItem(item)">
      <tm-icon svgIcon="close"></tm-icon>
    </span>
  </div>
</ng-template>

<ng-template #optionTemplate let-item="item">
  <span>
    <ng-container *ngTemplateOutlet="iconTemplate; context: { type: item.value.TYPE }"></ng-container>
    <ng-container *ngTemplateOutlet="colorTemplate; context: { value: item.value }"></ng-container>
    <ng-container *ngTemplateOutlet="helpTextTemplate; context: { type: item.value.TYPE }"></ng-container>
    <span [ngClass]="{ 'deleted-item': item.value.IS_DELETED }">{{ item.label }}</span> <br />
    <span
      *ngIf="
        additionalTypesToSelect &&
        searchSelectService.getSuggestionByMnemo(item.value.TYPE, additionalTypesToSelect) as suggestion
      "
      class="suggestion"
      >{{ suggestion }}</span
    >
  </span>
</ng-template>

<ng-template #iconTemplate let-type="type">
  <ng-container *ngIf="searchSelectService.getIconByType(type) as iconName">
    <img *ngIf="iconName.includes('.png'); else svgIcon" class="iconStyle" src="/img/icons/{{ iconName }}" />
    <ng-template #svgIcon>
      <iw-icon *ngIf="iconName" class="iconStyle" [svgIcon]="iconName"></iw-icon>
    </ng-template>
  </ng-container>
</ng-template>
<ng-template #colorTemplate let-value="value">
  <ng-container *ngIf="value.COLOR">
    <span [style.backgroundColor]="value.COLOR" class="colorIcon"></span>
  </ng-container>
</ng-template>
<ng-template #helpTextTemplate let-type="type">
  <ng-container *ngIf="typeToShowHelpText.includes(type)">
    <!-- @translate @tm-shared.search-select.form.techHelpers.text_object -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.form -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.stamp -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.table -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.category -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.fingerprint -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.graphic -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.card -->
    <!-- @translate @tm-shared.search-select.form.techHelpers.autoling -->
    <span>{{ '@tm-shared.search-select.form.techHelpers.' + type | translate }}: </span>
  </ng-container>
</ng-template>

<button
  class="openModal"
  iw-icon-button
  [disabled]="control?.disabled"
  type="button"
  (click)="openDialog()"
  data-qa="openDialogTrigger"
>
  <tm-icon svgIcon="create"></tm-icon>
</button>
