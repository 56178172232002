import { Component } from '@angular/core';
import { TmElement } from '../../custom-elements';
import { AdditionalItem, SearchParams } from '../tab-components/common';
import { TmeAbstractQueryComponent } from './tm-query-base.component';
import { WorkstationTabComponent } from '../tab-components/workstation-tab.component';
import { TmBookwormContactTypeService } from '../../bookworm';
import { map } from 'rxjs/operators';

@TmElement('tme-workstation')
@Component({
  templateUrl: 'wrapper.html',
  styleUrls: ['wrapper-style.scss'],
})
export class TmeWorkstationComponent extends TmeAbstractQueryComponent {
  public additionalItems = this.contactTypesService.getContactTypesFor(['workstation']).pipe(
    map((items) =>
      items.map((item) => {
        return <AdditionalItem>{
          mnemo: item.mnemo,
          name: item.name,
          masked: true,
        };
      })
    )
  );
  public components = [WorkstationTabComponent];
  public params: SearchParams = {
    queryParams: {
      type: 'query',
      scopes: 'workstation',
    },
  };

  constructor(private contactTypesService: TmBookwormContactTypeService) {
    super();
  }
}
