import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

export const VALIDATE_URL = '/api/textObject/normalize';

@Injectable()
export class TmTextObjectAsyncValidator {
  private _sessionValidationCache: Record<string, unknown> = {};

  constructor(private _http: HttpClient) {}

  // validate text object
  public getAsyncObjectValidation(textObjectId: string): AsyncValidatorFn {
    let initialValue: string | number;
    return (control: AbstractControl) => {
      if (!initialValue) {
        initialValue = control.value;
      } else if (initialValue === control.value) {
        return of(null);
      }

      if (control.pristine || !control.value) {
        return of(null);
      }

      return timer(300).pipe(
        switchMap(() => this._getRequestObj(control.value, textObjectId)),
        catchError((response: HttpErrorResponse) => of(response.error)),
        tap((data) => this._cacheValidationResponse(control.value, textObjectId, data)),
        map((data: any) => {
          return data.error
            ? {
                [data.error]: true,
              }
            : null;
        }),
        take(1)
      );
    };
  }

  private _sendRequestToCheck(text: string, id: string): Observable<any> {
    return this._http.get(VALIDATE_URL, {
      params: {
        id,
        text,
      },
    });
  }

  /**
   * get value from cache
   */
  private _tryToGetCachedValue(text: string, id: string): Observable<any> | null {
    const cachedResponse = this._sessionValidationCache[`${id}.${text}`];
    return cachedResponse ? of(cachedResponse) : null;
  }

  /**
   * cache validation response
   */
  private _cacheValidationResponse(text: string, id: string, responseFromServer: any): void {
    this._sessionValidationCache[`${id}.${text}`] = responseFromServer;
  }

  /**
   * Get request or cached value
   */
  private _getRequestObj(text: string, id: string): Observable<any> {
    return this._tryToGetCachedValue(text, id) || this._sendRequestToCheck(text, id);
  }
}
