import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { AbstractTabComponent } from './abstract-tab-component';
import { TmTreeComponent, TmTreeNodeData } from '../../tree';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import ConditionValueObject = TmApi.scope.ConditionValueObject;
import { CategoryTabService } from './category-tab.service';

@UntilDestroy()
@Component({
  templateUrl: 'category-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CategoryTabService],
})
export class CategoryTabComponent extends AbstractTabComponent implements OnInit {
  @ViewChild(TmTreeComponent) public set tree(tree: TmTreeComponent) {
    if (tree) {
      this._tree = tree;
      tree.onSelectionChanged.pipe(untilDestroyed(this)).subscribe(() => this.emitSelectionChange());
    }
  }

  public get tree() {
    return this._tree;
  }

  public static scope = 'category' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.category';

  public loading = true;

  public categories: Observable<TmTreeNodeData[]>;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: CategoryTabComponent.scope,
  };

  private _tree: TmTreeComponent;

  constructor(public service: TmSearchService, private categoryService: CategoryTabService) {
    super();
  }

  ngOnInit() {
    this.categories = this.service
      .get({
        params: {
          type: 'query',
          scopes: CategoryTabComponent.scope,
          'filter[TYPE]': 'term',
        },
      })
      .pipe(
        tap(() => (this.loading = false)),
        map((response) => this.categoryService.mapParentToChildren(response.data.category, this.selected))
      );
  }

  private emitSelectionChange() {
    const currentlySelected = this.selected.filter((item) => item.TYPE !== CategoryTabComponent.scope);

    const newSelected = this.tree
      .getSelectedIds(false, true)
      .map((id) => this.tree.getNodeById(id)?.data)
      .map(
        (item) =>
          <ConditionValueObject>{
            DATA: item.id,
            NAME: item.name,
            IS_DELETED: item.deleted,
            TYPE: CategoryTabComponent.scope,
          }
      );

    this.selectedChange.emit([...currentlySelected, ...newSelected]);
  }
}
