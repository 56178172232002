import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmStructureModule } from '@tm-shared/structure';
import { TmConfigElementsModule } from './config-elements.module';
import { TmConfigAffiliateService, TmConfigLocalService } from './services';
import { TmConfigWidgetService } from './config-widget/config-widget.service';
import { TmIconModule } from '../../@tm-shared/icons/tm-icon.module';
import { TmSpinnerModule } from '../../@tm-shared/spinner';
import { TmConfigModalComponent } from './config-modal/config-modal.component';
import { TmPrivilegesModule } from '../../@tm-shared/privileges/privileges.module';

const ru = require('./i18n/config.ru.json');
const en = require('./i18n/config.en.json');

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    TmConfigElementsModule,
    TranslateModule,
    TmStructureModule,
    IwSharedModule,
    TmSpinnerModule,
    TmIconModule,
    TmPrivilegesModule,
    TmConfigElementsModule,
  ],
  providers: [TmConfigLocalService, TmConfigAffiliateService],
  declarations: [TmConfigModalComponent],
})
export class TmConfigModule {
  constructor(_t: TranslateService, _widgetService: TmConfigWidgetService) {
    _t.setTranslation('ru', ru, true);
    _t.setTranslation('en', en, true);

    _widgetService.connect();
  }
}
