import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TmGridComponent, TmGridOptions } from '../../grid';
import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { sqlStringToDateTime } from '../../helpers/date';
import { AbstractTabComponent } from './abstract-tab-component';
import { takeUntil } from 'rxjs/operators';
import { getValueByScope } from '../../api-services/functions';
import ConditionValueObject = TmApi.scope.ConditionValueObject;

const IMAGE_CLASSIFIER_TYPES = ['graphic', 'card'];

@Component({
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageClassifierTabComponent extends AbstractTabComponent implements AfterViewInit {
  public static scope = 'graphic' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.image_classifier';

  @ViewChild('grid') public grid: TmGridComponent<any>;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: 'image_classifier',
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public options: TmGridOptions = {
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
      },
      {
        field: 'CREATE_DATE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.fingerprint.createDate'),
        valueFormatter: (params) => {
          return params.value ? sqlStringToDateTime(params.value).toFormat('f') : '';
        },
      },
      {
        field: 'NOTE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => this._t.instant('@tm-shared.search-select.headerNames.ldapStatus.note'),
      },
    ],
  };

  public static count(items: TmApi.scope.ConditionValueObject[]) {
    return items.filter((item) => IMAGE_CLASSIFIER_TYPES.includes(item.TYPE)).length || '';
  }

  public ngAfterViewInit() {
    const startingSelected = this.selected.filter((item) => IMAGE_CLASSIFIER_TYPES.includes(item.TYPE));
    const items = startingSelected.map((item) => item.DATA);
    if (items.length) {
      this.grid.selectById(items);
    }
    this.grid.allSelectedIds$.pipe(takeUntil(this.destroy$)).subscribe((currentlySelectedIds) => {
      const newSelected = currentlySelectedIds.map((id) => {
        const itemFromGrid = this.grid
          .getAllSelected()
          .find((item) => (item ? item[this.grid.idAttribute] === id : false));
        const previousSelected = startingSelected.find((item) => item.DATA === id);
        return itemFromGrid
          ? getValueByScope(itemFromGrid, this.grid.tableConfig.scopes!)
          : (previousSelected as ConditionValueObject);
      });

      const allItems = this.selected.filter((item) => !IMAGE_CLASSIFIER_TYPES.includes(item.TYPE));
      allItems.push(...newSelected);
      this.selectedChange.emit(allItems);
    });
  }
}
