import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TmSearchService } from '../../api-services';
import { TranslateService } from '@ngx-translate/core';
import { TableTabComponent } from './abstract-table-tab.component';
import { getCommonColumnDefinition } from './common';

@Component({
  templateUrl: './table.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogTabComponent extends TableTabComponent {
  public static scope = 'catalog' as const;
  public static i18nKey = '@tm-shared.search-select.searchTitles.catalog';

  public scopeToEmitOnChange = CatalogTabComponent.scope;

  public config: TmGrid.grid.TableConfigParams = {
    type: 'query',
    scopes: CatalogTabComponent.scope,
  };

  constructor(public service: TmSearchService, private _t: TranslateService) {
    super();
  }

  public options = getCommonColumnDefinition(this._t);
}
