import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IwSharedModule } from '@platform/shared';
import { TmGridModule } from '@tm-shared/grid';
import { TmFormElementsModule } from '@tm-shared/form-elements/form-elements.module';
import { TmStructureModule } from '@tm-shared/structure';
import { TmTreeModule } from '@tm-shared/tree';
import { TmSearchSelectComponent } from './search-select.component';
import { SearchSelectService } from './search-select.service';
import { TmSearchTabModalComponent } from './search-tab-modal.component';
import { PersonTabComponent } from './tab-components/person-tab.component';
import { NgxdModule } from '@ngxd/core';
import { StatusTabComponent } from './tab-components/status-tab.component';
import { GroupTabComponent } from './tab-components/group-tab.component';
import { WorkstationTabComponent } from './tab-components/workstation-tab.component';
import { TagTabComponent } from './tab-components/tag-tab.component';
import { DocumentTabComponent } from './tab-components/document-tab.component';
import { SearchTabHostComponent } from './tab-components/search-tab-host.component';
import { PolicyTabComponent } from './tab-components/policy-tab.component';
import { CatalogTabComponent } from './tab-components/catalog-tab.component';
import { PerimeterTabComponent } from './tab-components/perimeter-tab.component';
import { WebResourcesTabComponent } from './tab-components/web-resources-tab.component';
import { ApplicationTabComponent } from './tab-components/application-tab.component';
import { TextObjectTabComponent } from './tab-components/text-object-tab.component';
import { FingerprintTabComponent } from './tab-components/fingerprint-tab.component';
import { FormsTabComponent } from './tab-components/forms-tab.component';
import { StampTabComponent } from './tab-components/stamp-tab.component';
import { UnloadTableTabComponent } from './tab-components/unload-table-tab.component';
import { CategoryTabComponent } from './tab-components/category-tab.component';
import { ImageClassifierTabComponent } from './tab-components/image-classifier-tab.component';
import { AutolingTabComponent } from './tab-components/autoling-tab.component';
import { TmIconModule } from '../icons/tm-icon.module';
import { TmSpinnerModule } from '../spinner';
import { SearchableSearchService } from './searchable-search.service';
import { GroupTabWithoutDeletedGroupsComponent } from './tab-components/group-tab-without-deleted-groups';

const tabComponents = [
  StatusTabComponent,
  PersonTabComponent,
  GroupTabComponent,
  GroupTabWithoutDeletedGroupsComponent,
  WorkstationTabComponent,
  TagTabComponent,
  PolicyTabComponent,
  DocumentTabComponent,
  CatalogTabComponent,
  PerimeterTabComponent,
  WebResourcesTabComponent,
  ApplicationTabComponent,
  TextObjectTabComponent,
  FingerprintTabComponent,
  FormsTabComponent,
  StampTabComponent,
  UnloadTableTabComponent,
  CategoryTabComponent,
  ImageClassifierTabComponent,
  AutolingTabComponent,
];
type TabComponents = typeof tabComponents;
export type TabComponent = NonNullable<TabComponents[0]>;

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    IwSharedModule,
    TmIconModule,
    TranslateModule,
    TmStructureModule,
    TmTreeModule,
    TmSpinnerModule,
    TmGridModule,
    NgxdModule,
    TmFormElementsModule,
  ],
  exports: [TmSearchSelectComponent],
  providers: [SearchSelectService, SearchableSearchService],
  declarations: [TmSearchSelectComponent, TmSearchTabModalComponent, ...tabComponents, SearchTabHostComponent],
})
export class TmSearchSelectModule {}
