import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';

@TmElement('tme-spinner')
@Component({
  selector: 'tm-spinner',
  template: '<div class="tm-spinner"></div>',
  styleUrls: ['spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmSpinnerComponent implements OnInit {
  @Input() public size = '24px';

  constructor(private _elRef: ElementRef) {}

  public ngOnInit(): void {
    const el = this._elRef.nativeElement;
    el.style.width = el.style.height = this.size;
  }
}
