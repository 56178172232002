import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import Inputmask from 'inputmask';
import { DEFINITIONS } from './masked.model';
@Directive({
  selector: '[tmMask]',
})
export class InputMaskDirective implements OnChanges {
  @Input() public tmMask: string;

  constructor(private elementRef: ElementRef, private control: NgControl) {}

  public ngOnChanges(): void {
    if (this.tmMask) {
      Inputmask({
        mask: this.tmMask,
        greedy: false,
        definitions: DEFINITIONS,
        oncomplete: () => this.handleChange(),
        onincomplete: () => this.handleChange(),
        oncleared: () => this.handleChange(),
      }).mask(this.elementRef.nativeElement);
    } else {
      Inputmask.remove(this.elementRef.nativeElement);
    }
  }

  private handleChange(): void {
    this.control.control?.patchValue(this.unmask(this.elementRef.nativeElement.value));
  }

  // #KRT-22497
  private unmask(value: string) {
    const removeLastMaskSymbols = (charArr: string[]): string => {
      if (charArr.length && /[_ ]/.exec(charArr[charArr.length - 1])) {
        charArr.pop();
        return removeLastMaskSymbols(charArr);
      } else {
        return charArr.join('');
      }
    };
    return removeLastMaskSymbols(value.split(''));
  }
}
