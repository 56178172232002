<!--  @translate config.modal.mode.title.save -->
<!--  @translate config.modal.mode.title.apply -->
<!--  @translate config.modal.mode.title.revert -->
<iw-modal-header>{{ 'config.modal.mode.title.' + mode | translate }}</iw-modal-header>
<div class="modalText">
  <!--  @translate config.modal.mode.description.apply -->
  <!--  @translate config.modal.mode.description.save -->
  <!--  @translate config.modal.mode.description.revert -->
  {{ 'config.modal.mode.description.' + mode | translate }}
</div>
<iw-modal-footer>
  <button
    *ngIf="mode === 'apply'"
    iw-button
    [disabled]="loading"
    [disableClickEventOnly]="disableApply"
    [iwPopover]="disableApplyReason | translate"
    class="config-button"
    color="success"
    data-qa="confirmModalApply"
    (click)="apply()"
  >
    {{ 'config.modal.apply' | translate }}
  </button>
  <button
    *ngIf="mode === 'save' && !hideSaveButton"
    iw-button
    color="success"
    [disabled]="loading"
    class="config-button"
    data-qa="confirmModalSave"
    (click)="save()"
  >
    {{ 'config.modal.save' | translate }}
  </button>
  <button
    *ngIf="mode === 'revert'"
    iw-button
    [disabled]="loading"
    [disableClickEventOnly]="disableRevert"
    [iwPopover]="disableRevertReason | translate"
    class="config-button error-button"
    data-qa="confirmModalRevert"
    (click)="revert()"
  >
    {{ 'config.modal.revert' | translate }}
  </button>
  <button iw-button class="config-button" data-qa="confirmModalCancel" (click)="cancel()">
    {{ 'config.modal.cancel' | translate }}
  </button>
  <tm-spinner *ngIf="loading"></tm-spinner>
  <a
    *tmCan="'settings/audit_events/audit_event:show'"
    href="/settings/audit_events"
    target="_blank"
    class="audit-link"
    >{{ 'config.modal.audit' | translate }}</a
  >
</iw-modal-footer>
