<!-- HEADER -->
<div class="tmngHeaderFlexWrapper" *ngIf="title !== null && title !== undefined">
  <div class="tmngHeader">
    <h2 data-qa="content-header">{{ title || '' }}</h2>
    <p *ngIf="description" data-qa="content-description">{{ description }}</p>
  </div>
</div>

<!-- CONTENT -->
<div class="tmngContentFlexWrapper">
  <div
    class="tmngContent"
    [class.tmngScrollable]="scrollable"
    [class.tmngHeaded]="title"
    [class.tmngDefaultPadding]="defaultPadding"
  >
    <ng-content></ng-content>
  </div>
</div>
