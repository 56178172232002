import { Injectable } from '@angular/core';
import { TmBookwormContactTypeService } from '@tm-shared/bookworm';
import { AdditionalItem } from './tab-components/common';
import { MNEMO_TO_PATTERN } from '../helpers/patterns';
import ConditionValueObject = TmApi.scope.ConditionValueObject;

@Injectable()
export class SearchSelectService {
  constructor(private _contactType: TmBookwormContactTypeService) {}

  public mapToIwSelect(items: any[]): IwSelectItem[] | undefined {
    if (items) {
      return items.map((item) => {
        return {
          value: item,
          data: item,
          label: item.NAME,
        };
      });
    }
  }

  public unMapFromIwSelect(items?: any[]): TmApi.scope.ConditionValueObject[] {
    if (items) {
      return JSON.parse(JSON.stringify(items.map((item) => item.data || item)));
    }
    return [];
  }

  public getIconByType(type: string): string {
    const iconName = this._contactType.getIconNameByMnemo(type);
    if (iconName) {
      return iconName;
    }

    if (
      [
        'tag',
        'policy',
        'document',
        'application',
        'text_object',
        'fingerprint',
        'form',
        'category',
        'graphic',
        'card',
        'autoling',
        'table',
        'stamp',
        'catalog',
      ].includes(type)
    ) {
      return '';
    }

    return type;
  }

  /**
   * get translated icon name
   */
  public getSuggestionByMnemo(mnemo: string, additionalItems: AdditionalItem[]) {
    const contactItem = additionalItems.find((item) => item.mnemo === mnemo);
    return contactItem ? contactItem.name : null;
  }

  public addAdditionalItems(
    apiItems: IwSelectItem<ConditionValueObject>[] | undefined,
    currentItemsValue: any[],
    query: string,
    additionalItems: AdditionalItem[]
  ) {
    if (additionalItems && apiItems) {
      const currentItems = this.unMapFromIwSelect(currentItemsValue);

      const possibleItems = additionalItems
        .filter((item) => !item.hidden)
        .filter((item) => this.validateAgainstRegexps(item, query))
        .filter((addItem) =>
          // убираем дубликаты по типу и значению.
          // Возникают когда mnemo из additionalItems совпадает со скоупом api (поля "Приложение..")
          apiItems
            ? !apiItems.find((apiItem) => apiItem.data?.TYPE === addItem.mnemo && apiItem.data.DATA === query)
            : true
        )
        .filter(
          (item) =>
            // уже добавленные элементы для выбора не предлагать
            !currentItems.length ||
            !currentItems.find((current) => current.TYPE === item.mnemo && current.NAME === query)
        )
        .map((item) => ({
          TYPE: item.mnemo,
          KEY: item.mnemo,
          NAME: query,
          DATA: query,
        }));
      const iwSelectItems = this.mapToIwSelect(possibleItems);
      if (iwSelectItems) {
        apiItems.push(...iwSelectItems);
      }
    }
    return apiItems;
  }

  // выводить для выбора только элементы проходящие по регуляркам
  private validateAgainstRegexps(item: AdditionalItem, query: string) {
    if (item.masked && (query.startsWith('?') || query.startsWith('*') || query.endsWith('?') || query.endsWith('*'))) {
      return true;
    }
    if (item.mnemo in MNEMO_TO_PATTERN) {
      return MNEMO_TO_PATTERN[item.mnemo as keyof typeof MNEMO_TO_PATTERN].test(query);
    }
    return true;
  }
}
