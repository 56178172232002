import { DateTime } from 'luxon';

export const LICENSE_API = {
  licenses: '/api/license',
  parse: '/api/license/parse',
};

export const EXPIRE_SOON_DAYS_TRESHOLD = 7;
export const MODEL_ID_KEY = 'fingerprint';
export const MODEL_NAME_KEY = 'issue_date';

export enum TmLicenseStatus {
  Expired,
  Active,
  Reserved,
}
export class TmLicense {
  public id: string;
  public name: string;
  public active_days: number;
  public issue_date: string;
  public issuer: string;
  public licensee: string;
  public features: TmLicenseFeature[];
  public status: TmLicenseStatus;
  public licensed_users_count: number;

  public get isActive(): boolean {
    return this.status === TmLicenseStatus.Active;
  }

  public get isExpired(): boolean {
    return this.status === TmLicenseStatus.Expired;
  }

  public get isReserved(): boolean {
    return this.status === TmLicenseStatus.Reserved;
  }

  public get isExpireSoon(): boolean {
    return this.expire_date.diffNow('days').days < EXPIRE_SOON_DAYS_TRESHOLD;
  }

  public get expire_date(): DateTime {
    return DateTime.fromFormat(this.issue_date, 'yyyy-LL-dd').plus({ days: this.active_days });
  }

  constructor(model: TmLicenseDataModel) {
    this.id = model[MODEL_ID_KEY];
    this.name = model[MODEL_NAME_KEY]; // MOCK
    this.active_days = model.active_days;
    this.issue_date = model.issue_date;
    this.issuer = model.issuer;
    this.licensee = model.licensee;
    this.status = model.status;
    this.features = model.features;
    this.licensed_users_count = Number(model.meta.users);
  }
}

export class TmLicenseFeatureContainer {
  public technologyFeatures: TmTechnologyFeature[] = [];
  public interceptFeatures: TmInterceptFeature[] = [];
  public autoupdateFeatures: TmAutoupdateFeature[] = [];
  public importFeatures: TmImportFeature[] = [];

  constructor(models: TmLicense[]) {
    return this._gatherFeatureContainer(models);
  }

  private _gatherFeatureContainer(models: TmLicense[]): TmLicenseFeatureContainer {
    return models.reduce((result, { features }) => {
      features.forEach((feature) => {
        if (isTechnologyFeature(feature)) {
          result.technologyFeatures.push(feature);
        } else if (isInterceptFeature(feature)) {
          result.interceptFeatures.push(feature);
        } else if (isAutoupdateFeature(feature)) {
          result.autoupdateFeatures.push(feature);
        } else if (isImportFeature(feature)) {
          result.importFeatures.push(feature);
        }
      });

      return result;
    }, this);
  }
}

export interface TmLicenseResponse {
  data?: TmLicenseDataModel[];
  debug?: any;
  error?: string;
  state: string;
  status: number;
  ok: boolean;
}

export interface TmLicenseDataModel {
  active_days: number;
  features: TmLicenseFeature[];
  fingerprint: string;
  hwids: any[];
  issue_date: string;
  issuer: string;
  licensee: string;
  licserv_hwids: any[];
  meta: TmLicenseDataModelMeta;
  signature: string;
  status: number;
}

export interface TmLicenseDataModelMeta {
  context: string;
  restrictions: string;
  type: string;
  users: string;
}

export type TmLicenseFeature = TmTechnologyFeature | TmInterceptFeature | TmAutoupdateFeature | TmImportFeature;

export interface TmTechnologyFeature {
  cas: string;
}

export interface TmInterceptFeature {
  common_name: string;
  protocol: string;
  object_type: string;
  origin?: string;
}

export interface TmAutoupdateFeature {
  common_name: string;
  data_type: string;
  tech_type: string;
}

export interface TmImportFeature {
  common_name: string;
  importer_name: string;
}

export function isTechnologyFeature(feature: TmLicenseFeature): feature is TmTechnologyFeature {
  return 'cas' in feature;
}

export function isInterceptFeature(feature: TmLicenseFeature): feature is TmInterceptFeature {
  return 'protocol' in feature;
}

export function isAutoupdateFeature(feature: TmLicenseFeature): feature is TmAutoupdateFeature {
  return 'data_type' in feature;
}

export function isImportFeature(feature: TmLicenseFeature): feature is TmImportFeature {
  return 'importer_name' in feature;
}
