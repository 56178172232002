import { CheckboxCellComponent } from '../../grid/cell-renderers';
import { TranslateService } from '@ngx-translate/core';
import { TmGridOptions } from '../../grid';
import { Observable } from 'rxjs';

export interface AdditionalItem {
  // маппится в TYPE в запросах
  mnemo: string;
  // для скрытия доп. Item'ов из отображения в выпадающем списке
  hidden?: boolean;
  // подпись для элемента в выпадающем списке
  name?: string;
  // модифицирует регулярку: [?*]исходная[?*] обеспечивая поиск по маске
  masked?: boolean;
}

export const ADDITIONAL_ITEMS = {
  URL: {
    mnemo: 'url',
    masked: true,
  },
  APPLICATION: {
    mnemo: 'application',
    masked: true,
  },
};

export interface Searchable {
  // query backend by name
  search: (params: { query: string; [key: string]: string }) => Observable<TmApi.scope.ConditionValueObject[]>;
}

export interface SearchParams {
  api?: Searchable;
  queryParams: {
    [key: string]: string | string[];
  };
  tabParams?: {
    [key: string]: any;
  };
}

export function getCommonColumnDefinition(t: TranslateService) {
  return <TmGridOptions>{
    sortingOrder: ['desc', 'asc'],
    columnDefs: [
      {
        width: 30,
        field: 'checkbox',
        headerName: '',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        field: 'DISPLAY_NAME',
        sort: 'asc',
        resizable: true,
        sortable: true,
        headerValueGetter: () => t.instant('@tm-shared.search-select.headerNames.ldapStatus.displayName'),
      },
      {
        field: 'NOTE',
        resizable: true,
        sortable: true,
        headerValueGetter: () => t.instant('@tm-shared.search-select.headerNames.ldapStatus.note'),
      },
    ],
  };
}
