import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { TmElement } from '@tm-shared/custom-elements';
import { Observable, Subject, forkJoin, from, fromEvent, of } from 'rxjs';
import { catchError, debounceTime, delay, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TmLazyBlock, TmLazyBlockInternal, TmLazyBlockState, TmLazyBlocksUpdate } from './lazy-blocks-interfaces';

@TmElement('tme-lazy-blocks')
@Component({
  selector: 'tm-lazy-blocks',
  templateUrl: './lazy-blocks.component.html',
  styleUrls: ['./lazy-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmLazyBlocksComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public items: TmLazyBlock[] = [];

  /**
   * This offset (top and bottom) is used to calculate which blocks should be rendered/loaded
   */
  @Input() public loadportOffsetPx: number | string = document.body.offsetHeight || 1000;

  /**
   * Maximum blocks to be loaded per single _loadBlock task
   */
  @Input() public maxLoadThreads: number | string = 3;

  @Input() public scrollDebounceMs: number | string = 20;

  @Input() public loadMore?: (length: number) => Promise<TmLazyBlock[]>;

  public blocksInLoadport: TmLazyBlockInternal[] = [];

  public offsetTop = 0;

  public offsetBottom = 0;

  private _emptyContent = false;

  private _blocksCache: TmLazyBlockInternal[] = [];

  private _loadMoreDisabled = false;

  @ViewChild('container', { static: true }) private _container: ElementRef;

  private _blockHasVisualChanges$: Subject<number> = new Subject();

  private _destroy$: Subject<number> = new Subject();

  constructor(_cd: ChangeDetectorRef) {
    this._blockHasVisualChanges$.pipe(takeUntil(this._destroy$)).subscribe(() => _cd.markForCheck());
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnChanges(change: any): void {
    if (change.items || change.loadMore) {
      this._start();
    }
  }

  public ngAfterViewInit(): void {
    fromEvent(this._container.nativeElement, 'scroll')
      .pipe(takeUntil(this._destroy$), debounceTime(+this.scrollDebounceMs))
      .subscribe(() => this._loadBlocks());
  }

  private _start(): void {
    // Scenario with initial items
    if (this.items && this.items.length) {
      this._initBlocksCacheWith(this.items);
      this._loadBlocks();
      return;
    }

    // Scenario with no initial items but with loadMore feature enabled
    if ((!this.items || !this.items.length) && this.loadMore) {
      this._loadMoreBlocks().then((blocks: TmLazyBlock[]) => {
        if (blocks.length) {
          this._initBlocksCacheWith(blocks);
          this._loadBlocks();
        }
      });
    }
  }

  private _initBlocksCacheWith(blocks: TmLazyBlock[]): void {
    this._blocksCache = blocks.map((loader, i) =>
      Object.assign({}, loader, { index: i, state: TmLazyBlockState.untouched })
    );
  }

  private _appendBlocksToCache(blocks: TmLazyBlock[]): void {
    let indexOffset = this._blocksCache.length;
    this._blocksCache.push(
      ...blocks.map((loader, i) =>
        Object.assign({}, loader, { index: indexOffset + i, state: TmLazyBlockState.untouched })
      )
    );
  }

  private _loadBlocks(_safetyCounter: number = this.items.length || 1): void {
    if (!_safetyCounter) {
      if (ENV !== 'production') {
        throw new Error(`Possibility of loading blocks in cycle`);
      }
      return;
    }

    if (this._hasEmptyContent()) {
      return;
    }

    let update: TmLazyBlocksUpdate = this._calculateBlocksUpdate();

    /**
     * Load blocks unless there is no changes
     */
    if (update.hasChanges) {
      // Wait for current loading tasks, let UI update, check more blocks, then run next round
      this._commitBlocksUpdate(update)
        .pipe(
          take(1),
          takeUntil(this._destroy$),
          switchMap(() => this._loadMoreBlocks()),
          delay(0)
        )
        .subscribe((moreBlocks: TmLazyBlock[]) => {
          this._updateRenderedBlocksDimensions();
          this._appendBlocksToCache(moreBlocks);
          this._loadBlocks(_safetyCounter--);
        });
    }
  }

  private _loadMoreBlocks(): Promise<TmLazyBlock[]> {
    if (
      this.loadMore &&
      !this._loadMoreDisabled &&
      (!this._blocksCache.length || this._blocksCache.slice(-1)[0].visible)
    ) {
      return this.loadMore(this._blocksCache.length).then((blocks) => {
        this._loadMoreDisabled = !blocks.length;
        return blocks;
      });
    }

    return Promise.resolve([]);
  }

  private _updateRenderedBlocksDimensions(): void {
    // Update actual dimensions for visible blocks
    let blockEls = Array.from((this._container.nativeElement as HTMLElement).querySelectorAll('[data-lazy-block]'));
    blockEls.forEach((el: HTMLElement) => {
      let block = this._blocksCache[parseInt(el.dataset.index!, 10)];
      block.height = el.offsetHeight;
      block.offsetTop = el.offsetTop;
    });
  }

  private _updateOffsets(): void {
    let processTopOffset = true;
    this.offsetBottom = 0;
    this.offsetTop = 0;

    for (let i = 0; i < this._blocksCache.length; i++) {
      let block = this._blocksCache[i];

      if (this._checkIfBlockIsUntouched(block)) {
        break;
      }

      if (processTopOffset && block.visible) {
        processTopOffset = false;
      } else if (processTopOffset && !block.visible) {
        this.offsetTop += block.height || 0;
      } else if (!processTopOffset && !block.visible) {
        this.offsetBottom += block.height || 0;
      }
    }
  }

  private _commitBlocksUpdate(update: TmLazyBlocksUpdate): Observable<TmLazyBlocksUpdate> {
    // Empty loadport
    this.blocksInLoadport = [];

    // Run tasks
    let hideTasks = update.hide.map((b) => this._hideBlockFromLoadport(b));
    let appendTasks = update.show.map((b) => this._appendBlockToLoadport(b));

    this._updateOffsets();
    return forkJoin(hideTasks.concat(appendTasks)).pipe(map(() => update));
  }

  private _hideBlockFromLoadport(block: TmLazyBlockInternal): Observable<void> {
    block.visible = false;
    return of(undefined);
  }

  private _appendBlockToLoadport(block: TmLazyBlockInternal): Observable<void> {
    let result$: Observable<void>;

    this.blocksInLoadport.push(block);

    if (this._checkIfBlockIsUntouched(block)) {
      block.loading = true;

      result$ = from(block.loader()).pipe(
        map((content) => {
          this._setBlockContent(block, content);
          return undefined;
        }),
        catchError((e) => {
          this._setBlockError(block, e);
          return of(undefined);
        })
      );
    } else {
      result$ = of(undefined);
    }

    block.visible = true;
    this._blockHasVisualChanges$.next(block.index);

    return result$;
  }

  private _setBlockContent(block: TmLazyBlockInternal, content: string): void {
    block.loading = false;
    block.state = TmLazyBlockState.contentReady;
    block.content = content;
    this._blockHasVisualChanges$.next(block.index);
  }

  private _setBlockError(block: TmLazyBlockInternal, e: any): void {
    block.state = TmLazyBlockState.error;
    block.content = e;
    this._blockHasVisualChanges$.next(block.index);
  }

  private _hasEmptyContent(): boolean {
    if (this._emptyContent) {
      return this._emptyContent;
    }

    if (!this.items.length && this._loadMoreDisabled) {
      return (this._emptyContent = true);
    }

    if (this._blocksCache.every((block) => this._checkIfBlockIsEmpty(block))) {
      return (this._emptyContent = true);
    }

    return false;
  }

  private _checkIfBlockIsEmpty(block: TmLazyBlockInternal): boolean {
    return block.state === TmLazyBlockState.contentReady && !block.content;
  }

  private _checkIfBlockIsLoaded(block: TmLazyBlockInternal): boolean {
    return block.state === TmLazyBlockState.contentReady || block.state === TmLazyBlockState.error;
  }

  private _checkIfBlockIsUntouched(block: TmLazyBlockInternal): boolean {
    return block.state === TmLazyBlockState.untouched;
  }

  private _calculateBlocksUpdate(): TmLazyBlocksUpdate {
    let blocksToHide: TmLazyBlockInternal[] = Array.from(this.blocksInLoadport);
    let blocksToShow: TmLazyBlockInternal[] = this._getBlocksToBeInLoadport();

    for (let i = blocksToHide.length - 1; i >= 0; i--) {
      if (blocksToShow.includes(blocksToHide[i])) {
        blocksToHide.splice(i, 1);
      }
    }

    // Hide blocks in correct order
    if (blocksToHide.length && blocksToShow.length && blocksToHide[0].index > blocksToShow[0].index) {
      blocksToHide.reverse();
    }

    return {
      hasChanges: blocksToHide.length > 0 || blocksToShow.some((b) => !b.visible),
      hide: blocksToHide,
      show: blocksToShow,
    };
  }

  private _isOffsetInLoadport(offset: number): boolean {
    let containerEl = this._container.nativeElement;
    let min = containerEl.scrollTop - +this.loadportOffsetPx;
    let max = containerEl.scrollTop + containerEl.clientHeight + this.loadportOffsetPx;

    return offset > min && offset < max;
  }

  /**
   * Return blocks that should be loaded
   */
  private _getBlocksToBeInLoadport(): TmLazyBlockInternal[] {
    let blocksInLoadport: TmLazyBlockInternal[] = [];
    let _offset = 0;
    let threadLimit: number = +this.maxLoadThreads;

    for (let i = 0; i < this._blocksCache.length; i++) {
      let block = this._blocksCache[i];

      // Prevent unloaded blocks to flood result
      if (!this._checkIfBlockIsLoaded(block) && !threadLimit--) {
        break;
      }

      _offset += block.height || 0;

      if (this._isOffsetInLoadport(_offset)) {
        blocksInLoadport.push(block);
      }
    }

    return blocksInLoadport;
  }
}
