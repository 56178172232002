<iw-form
  data-qa="analysisTextPatternForm"
  class="form"
  [formGroup]="form"
  (ngSubmit)="form.valid && submit()"
  [readonly]="readonly"
>
  <iw-form-row>
    <label iwFormRowLabel>
      {{ 'analysis-text.pattern.isEnabled' | translate }}
      <span iwTooltip="{{ 'analysis-text.pattern.isEnabledTooltip' | translate }}">?</span>
    </label>
    <iw-checkbox
      iwFormRowContent
      formControlName="isEnabled"
      [indicatorTemplate]="indicatorTemplate"
      data-qa="isEnabled"
    ></iw-checkbox>
  </iw-form-row>

  <iw-form-row *ngIf="!readonly">
    <label iwFormRowLabel translate>analysis-text.pattern.type</label>
    <iw-button-toggle-group formControlName="isRegexp" iwFormRowContent>
      <iw-button-toggle [value]="false" (click)="textService.resetValidationErrors()">
        <a iw-button translate>analysis-text.pattern.typeString</a>
      </iw-button-toggle>
      <iw-button-toggle [value]="true" (click)="textService.resetValidationErrors()">
        <a iw-button translate>analysis-text.pattern.typeRegexp</a>
      </iw-button-toggle>
    </iw-button-toggle-group>
  </iw-form-row>

  <iw-form-row class="input-pattern">
    <label *ngIf="form.value.isRegexp" iwFormRowLabel translate>
      analysis-text.pattern.typeRegexp
    </label>
    <label *ngIf="!form.value.isRegexp" iwFormRowLabel translate>
      analysis-text.pattern.typeString
    </label>
    <iw-input-container iwFormRowContent>
      <textarea class="patternInput" data-qa="analysisTextPatternInput" formControlName="TEXT" iwInput></textarea>
      <!--
          @translate analysis-text.pattern.validation.pattern
          @translate analysis-text.pattern.validation.maxlength
          @translate analysis-text.pattern.validation.minlength
          @translate analysis-text.pattern.validation.required
        -->
      <div class="validationErrors" iwErrorHints *ngFor="let error of getErrors('TEXT')">
        {{ 'analysis-text.pattern.validation.' + error.key | translate: error.value }}
      </div>
    </iw-input-container>
  </iw-form-row>

  <iw-form-row *ngIf="form.value.isRegexp">
    <label iwFormRowLabel>
      {{ 'analysis-text.pattern.checkPatternInput' | translate }}
    </label>
    <tm-analysis-text-pattern-validator
      iwFormRowContent
      [isSystem]="!!data.IS_SYSTEM"
      [patternId]="data.TEXT_OBJECT_PATTERN_ID"
      [pattern]="form.value.TEXT"
    >
    </tm-analysis-text-pattern-validator>
  </iw-form-row>

  <iw-form-row *ngIf="!readonly || data.NOTE">
    <label iwFormRowLabel>
      {{ 'analysis-text.pattern.description' | translate }}
    </label>
    <iw-input-container iwFormRowContent>
      <textarea class="patternInput" data-qa="analysisTextPatternNote" formControlName="description" iwInput></textarea>
    </iw-input-container>
  </iw-form-row>

  <iw-form-toolbar *ngIf="!readonly">
    <button iw-button type="submit" color="success" data-qa="analysisTextUserSubmit" [disabled]="form.invalid">
      {{ 'analysis-text.pattern.save' | translate }}
    </button>
    <button iw-button type="button" (click)="close()">
      {{ 'analysis-text.pattern.cancel' | translate }}
    </button>
  </iw-form-toolbar>
</iw-form>

<ng-template #indicatorTemplate let-checked="checked">
  <tm-tumbler-indicator [checked]="checked" [disabled]="form.controls.isEnabled.disabled"></tm-tumbler-indicator>
</ng-template>
