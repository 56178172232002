<iw-modal-header>
  {{ modalHeader }}
</iw-modal-header>

<tm-tabset>
  <tm-tab *ngFor="let comp of components" tabTitle="{{ comp.i18nKey | translate }} {{ getItemsCount(comp) }}">
    <ng-template>
      <div class="tab-container">
        <tm-search-tab-host
          [component]="comp"
          [(selected)]="selected"
          [tabParams]="tabParams ? tabParams[comp.name] : null"
        ></tm-search-tab-host>
      </div>
    </ng-template>
  </tm-tab>
</tm-tabset>

<iw-modal-footer>
  <button class="button" iw-button color="success" (click)="acceptChanges()">
    {{ '@tm-shared.search-select.modal.accept' | translate }}
  </button>
  <button class="button" iw-button (click)="rejectChanges()">
    {{ '@tm-shared.search-select.modal.reject' | translate }}
  </button>
</iw-modal-footer>
